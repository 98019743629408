import React from 'react';

export type Props = {
	color?: string;
};

const Snow: React.FC<Props> = ({ color }) => {
	return (
		<svg id="SnowSVG" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M4.38157 11.6279C4.6644 11.6279 4.89367 11.3986 4.89367 11.1158C4.89367 10.833 4.6644 10.6037 4.38157 10.6037C4.09874 10.6037 3.86947 10.833 3.86947 11.1158C3.86947 11.3986 4.09874 11.6279 4.38157 11.6279Z" fill={color ?? "#25465F"} />
			<path d="M7.96441 11.8921C8.24724 11.8921 8.47652 11.6628 8.47652 11.38C8.47652 11.0972 8.24724 10.8679 7.96441 10.8679C7.68159 10.8679 7.45231 11.0972 7.45231 11.38C7.45231 11.6628 7.68159 11.8921 7.96441 11.8921Z" fill={color ?? "#25465F"} />
			<path d="M7.07786 14.6069C7.36068 14.6069 7.58996 14.3776 7.58996 14.0948C7.58996 13.812 7.36068 13.5827 7.07786 13.5827C6.79503 13.5827 6.56576 13.812 6.56576 14.0948C6.56576 14.3776 6.79503 14.6069 7.07786 14.6069Z" fill={color ?? "#25465F"} />
			<path d="M10.525 13.5442C10.8078 13.5442 11.0371 13.3149 11.0371 13.0321C11.0371 12.7493 10.8078 12.52 10.525 12.52C10.2422 12.52 10.0129 12.7493 10.0129 13.0321C10.0129 13.3149 10.2422 13.5442 10.525 13.5442Z" fill={color ?? "#25465F"} />
			<path d="M12.689 11.8921C12.9719 11.8921 13.2011 11.6628 13.2011 11.38C13.2011 11.0972 12.9719 10.8679 12.689 10.8679C12.4062 10.8679 12.1769 11.0972 12.1769 11.38C12.1769 11.6628 12.4062 11.8921 12.689 11.8921Z" fill={color ?? "#25465F"} />
			<path d="M12.1768 15.4126C12.4596 15.4126 12.6889 15.1834 12.6889 14.9005C12.6889 14.6177 12.4596 14.3884 12.1768 14.3884C11.894 14.3884 11.6647 14.6177 11.6647 14.9005C11.6647 15.1834 11.894 15.4126 12.1768 15.4126Z" fill={color ?? "#25465F"} />
			<path d="M4.03456 14.9006C4.31739 14.9006 4.54667 14.6713 4.54667 14.3885C4.54667 14.1057 4.31739 13.8764 4.03456 13.8764C3.75174 13.8764 3.52246 14.1057 3.52246 14.3885C3.52246 14.6713 3.75174 14.9006 4.03456 14.9006Z" fill={color ?? "#25465F"} />
			<path d="M16.2773 6.58208C16.2773 7.80636 15.2825 8.8012 14.0582 8.8012H3.03976C1.51447 8.8012 0.277344 7.56407 0.277344 6.03327C0.277344 4.50247 1.51447 3.27085 3.03976 3.27085C3.03976 1.46105 4.50632 0 6.31061 0C7.59913 0 8.71327 0.745211 9.24373 1.83182C9.6402 1.6134 10.1027 1.48492 10.5873 1.48492C12.1181 1.48492 13.3552 2.72204 13.3552 4.25284C13.3552 4.32626 13.3516 4.40335 13.3461 4.47677C13.57 4.39968 13.8068 4.35746 14.0582 4.35746C15.2825 4.35746 16.2773 5.3523 16.2773 6.58208Z" fill={color ?? "#25465F"} />
		</svg>
	);
};

export default Snow;
