import React, { useEffect } from 'react';

import Calendar from 'widgets/Calendar';
import { Flex } from 'styles';
import { useNavigate } from 'react-router-dom';
import routes from 'utils/routes';
import { useLocalStorageState } from 'utils/common';
import { getMyWorksite } from 'api/routes/worksite';
import { useClient } from 'providers/client';
import { useUser } from 'providers/user';
import { useKeycloak } from 'providers/keycloak';
import i18n from 'utils/lang';

const Home: React.FC = () => {
	const userInfos= useUser();
	const navigate = useNavigate();
	const { clientId } = useKeycloak();
	const { clientHandler: { client } } = useClient();
	const [hasBeenToWorksiteList, setHasBeenToWorksiteList] = useLocalStorageState<boolean | undefined>(`hasBeenToWorksiteList-${userInfos?.infos.name ?? ''}`, undefined);

	useEffect(() => {
		if (!clientId) return;
		(async () => {
			if (!hasBeenToWorksiteList) {
				const userWorksiteData = await getMyWorksite(client);
				const clients = userWorksiteData.map((e) => e.keycloak_client);

				if (userWorksiteData.length === 0) {
					alert(i18n.t("unauthorized_user"))
					userInfos?.logout();
				} else if (!clients.includes(clientId) && userWorksiteData.length > 0) {
					navigate(`/worksite/${userWorksiteData[0].code}`)
				} else {
					setHasBeenToWorksiteList(true);
					if (userWorksiteData.length > 1) {
						navigate(routes.worksite_list);
					}
				}
			}
		})().catch(console.error);
	}, [clientId]);

	return (
		<Flex height={'100%'}>
			<Calendar />
		</Flex>
	);
};

export default Home;
