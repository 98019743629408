import React, { createContext, PropsWithChildren, useEffect, useState, useContext } from "react";

import { Comment } from "api/types/comment";
import { useReports } from "providers/ReportsProvider";
import { useClient } from "providers/client";
import { getAllComment, upsertReportCommentByTask } from "api/routes/comment";
import { showError } from "utils/error";
import Loader, { LoadingType } from "components/Loader";
import { CounterByTask } from "api/types/counter";


type TaskComment = {
    comments: Comment[];
    getCommentByTask: (task: CounterByTask) => Comment | undefined;
    updateCommentByTask: (task: CounterByTask, newValue: string) => void;
}

const TaskCommentContext = createContext<TaskComment>({
    comments: [],
    getCommentByTask: () => undefined,
    updateCommentByTask: () => { },
})


export const TaskCommentProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { clientHandler: { client } } = useClient();
    const { currentReport } = useReports();
    const [comments, setComments] = useState<Comment[]>();
    const [refresh, setRefresh] = useState(false);

    const forceRefresh = (): void => setRefresh(!refresh);

    useEffect(() => {
        if (currentReport?._id) {
            getAllComment(client, currentReport._id, "byTask")
                .then(setComments)
                .catch(showError("Failed to fetch all comments by task"))
        }
    }, [currentReport?._id, refresh])

    if (!comments) {
        return <Loader type={LoadingType.Default} />
    }

    const getCommentByTask = (task: CounterByTask): Comment | undefined => comments.find((e) => !!e.task && e.task === task.rawTask);
    const updateCommentByTask = (task: CounterByTask, newValue: string, shouldForceUpdate = false): void => {
        if (!currentReport?._id || task.rawTask === undefined && task.task === undefined) return;

        upsertReportCommentByTask(
            client,
            currentReport._id,
            { task: task.rawTask ?? task.task ?? '', content: newValue }
        ).then(() => {
            if (shouldForceUpdate) {
                setTimeout(() => {forceRefresh()}, 500)
            }
        }).catch(showError(`Failed to update comment`));
    }

    return (
        <TaskCommentContext.Provider value={{ comments, getCommentByTask, updateCommentByTask }}>
            {children}
        </TaskCommentContext.Provider>
    );

}

export const useTaskComment: () => TaskComment = () => useContext(TaskCommentContext);