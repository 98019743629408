import styled from "styled-components";

const Container = styled.div`
    display: flex;
    position: relative;
`;

export const WrapperPopUp = styled.div`
    position: absolute;
    top: 30px;
    width: 300px;
    max-height: 20vh;
`;

export const TitleAndCrossContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: default;
    width: 100%;
`

export const ContainerPopUp = styled.div`
    background-color: white;
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    padding: 20px;
`;

export const FlexPopUp = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    select {
        border: 1px solid black;
    }
`;

export const TitlePopUp = styled.div`
    font-size: 1.5rem;
    line-height: 1rem;
    font-weight: bold;
    color: black;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    a {
        padding: 5px 80px;
        width: 150px;
        max-height: 100px;
        cursor: pointer;
    }
`
type Props = {
    isClickable: boolean;
}

export const TitlePadding = styled.div<Props>`
	padding-right: 10px;
	padding-left: 10px;
	font-weight: bold;
    color: white;
    cursor: ${({ isClickable }) => isClickable ? 'pointer' : 'default'};
`;

export default Container;