import React, { useEffect } from 'react';
import { addDays, format, parseISO, startOfWeek } from 'date-fns';

import { useLocalStorageState } from 'utils/common';
import { counterHasData } from 'api/routes/counter';
import { useReports } from 'providers/ReportsProvider';
import { usePreferences } from 'providers/preferences';
import { useClient } from 'providers/client';
import { showError } from 'utils/error';
import { useKeycloak } from 'providers/keycloak';
import { getLocaleFromCode } from 'utils/lang';

import CalendarDay from './components/Day';
import CalendarHeader from './components/Header';
import Container, { WeekContainer } from './style';

const Calendar: React.FC = () => {
	const { keycloak } = useKeycloak();
	const { preferences } = usePreferences();
	const { clientHandler: { client } } = useClient();
	const { reports, refreshAllReports } = useReports();
	const [currentDate, setCurrentDate] = useLocalStorageState<Date>(
		'homepage-calendar',
		startOfWeek(Date.now(), { locale: getLocaleFromCode(preferences.language) }),
		keycloak,
		(value) => parseISO(value),
	);
	const [shouldRefreshCalendar, setShouldRefreshCalendar] = React.useState(false);
	const [hasData, setHasData] = React.useState([] as boolean[]);

	const updateCurrentDate = (date: Date): void => {
		setShouldRefreshCalendar(true);
		setCurrentDate(startOfWeek(date, { locale: getLocaleFromCode(preferences.language) }));
	}

	useEffect(() => {
		updateCurrentDate(currentDate);
	}, [preferences.language]);

	useEffect(() => {
		if (shouldRefreshCalendar) {
			setShouldRefreshCalendar(false);
			refreshAllReports({ startDate: currentDate, endDate: addDays(currentDate, 6) });

			const endWeek = addDays(currentDate, 6);
			counterHasData(
				client,
				new Date(format(currentDate, 'yyyy-MM-dd')),
				new Date(format(endWeek, 'yyyy-MM-dd'))
			).then((results) => setHasData(results.map((data) => data.hasData))
			).catch(showError(`Failed to fetch counter by sources`));
		}
	}, [currentDate]);

	return (
		<Container>
			<CalendarHeader
				currentDate={currentDate}
				setCurrentDate={updateCurrentDate}
				refreshReport={() => updateCurrentDate(currentDate)}
			/>
			<WeekContainer>
				{[...Array<number>(7)].map((_, i) => {
					const day = addDays(currentDate, i);
					const filteredReports = reports.filter((e) =>
						e?.dateReport === format(day, 'yyyy-MM-dd', { locale: getLocaleFromCode(preferences?.language) }));
					return (
						<CalendarDay
							key={`${format(day, 'yyyy-MM-dd')}-${i}`}
							day={day}
							reports={filteredReports}
							allReports={reports}
							hasData={hasData[i]}
						/>
					);
				})}
			</WeekContainer>
		</Container>
	);
};

export default Calendar;
