import React from 'react';

export type Props = {
	color?: string;
};

const Sun: React.FC<Props> = ({ color }) => {
	return (
		<svg id="SunSVG" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.58687 0V2.80342H8.58687V0H7.58687Z" fill={color ?? "#25465F"}/>
			<path d="M12.6925 7.99017C12.6925 10.4232 10.7201 12.3955 8.28712 12.3955C5.8541 12.3955 3.88175 10.4232 3.88175 7.99017C3.88175 5.55715 5.8541 3.5848 8.28712 3.5848C10.7201 3.5848 12.6925 5.55715 12.6925 7.99017Z" fill={color ?? "#25465F"}/>
			<path d="M7.58687 16V13.1966H8.58687V16H7.58687Z" fill={color ?? "#25465F"}/>
			<path d="M0.277344 7.69043L3.08076 7.69043V8.69043L0.277344 8.69043L0.277344 7.69043Z" fill={color ?? "#25465F"}/>
			<path d="M16.2773 7.69043H13.4739V8.69043H16.2773V7.69043Z" fill={color ?? "#25465F"}/>
			<path d="M2.63331 2.02985L4.61563 4.01217L3.90852 4.71928L1.92621 2.73696L2.63331 2.02985Z" fill={color ?? "#25465F"}/>
			<path d="M13.9626 13.359L11.9803 11.3767L11.2732 12.0838L13.2555 14.0661L13.9626 13.359Z" fill={color ?? "#25465F"}/>
			<path d="M14.8121 2.17234L12.8298 4.15466L12.1227 3.44755L14.105 1.46524L14.8121 2.17234Z" fill={color ?? "#25465F"}/>
			<path d="M2.91824 14.0661L4.90056 12.0838L4.19345 11.3767L2.21114 13.359L2.91824 14.0661Z" fill={color ?? "#25465F"}/>
		</svg>
	);
};

export default Sun;
