import React, { useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import { emptyFilterTranslationKey, useSearchAndFilter } from 'widgets/Report/provider/counters/sources/searchAndFilter';
import { useSearchAndFilterResources } from 'widgets/Report/provider/counters/resources/searchAndFilter';
import { ModalBody, ModalContent, ModalHeader } from 'widgets/ReportHeader/components/Validation/style';
import { TitleAndCrossContainer, TitlePopUp } from 'components/Modal/SelectWorksite/style';
import { ToggleableFilterType } from 'widgets/Report/provider/counters/filter';
import { CrossContainer } from 'components/Modal/ModalHeader/style';
import Button, { Type as ButtonType } from 'components/Button';
import { FilterType } from 'utils/constants';
import { useModal } from 'components/Modal';
import { Flex, Margin } from 'styles';
import Cross from 'assets/svg/Cross';
import i18n from 'utils/lang';

import {
	BoldText,
	FilterButtonContainer,
	FilterTitle,
	Square,
} from './style';

type FilterButtonProps = {
	filter: FilterType;
	selected: boolean;
	onSelect: () => void;
};

const FilterButton: React.FC<FilterButtonProps> = ({ filter, selected, onSelect }) => {
	const [active, setActive] = useState(selected);

	return (
		<Margin all="5px">
			<Button 
				type={active ? ButtonType.filterSelected : ButtonType.filter} 
				onClick={() => {
					const newValue = !active;
					
					setActive(newValue);
					onSelect();
				}}
			>
				<Flex flexDirection="row">
					<Flex alignItems="center">
						<Square color={filter.color ?? 'red'} />
					</Flex>
					<FilterTitle>
						{Boolean(filter.name) ? filter.name : i18n.t(emptyFilterTranslationKey)}
					</FilterTitle>
				</Flex>
			</Button>
		</Margin>
	);
};

type FilterProps = {
	availableFilters: ToggleableFilterType[]
}

const FilterModal: React.FC<FilterProps> = ({ availableFilters }) => {
	const { pop } = useModal();
	const modalRef = useRef() as React.MutableRefObject<HTMLInputElement>;

	useOnClickOutside(modalRef, pop);

	return (
		<ModalBody ref={modalRef}>
			<ModalHeader>
				<TitleAndCrossContainer>
					<TitlePopUp>{i18n.t('select_filter')}</TitlePopUp>
					<CrossContainer onClick={pop}>
						<Cross />
					</CrossContainer>
				</TitleAndCrossContainer>
			</ModalHeader>
			<ModalContent>
				<Flex flexWrap='wrap' margin="10px 0 0 0">
					{
						availableFilters.map((filter: ToggleableFilterType, idx) => (
							<FilterButton
								key={`filter-modal-${idx}-${filter?.name ?? ''}`}
								selected={filter.active}
								onSelect={() => filter.toggle()}
								filter={filter}
							/>
						))
					}
				</Flex>
			</ModalContent>
		</ModalBody>
	)
}

const Filter: React.FC<FilterProps> = ({ availableFilters }) => {
	const { push } = useModal();

	return (
		<FilterButtonContainer
			alignItems="center"
			onClick={() => push(<FilterModal availableFilters={availableFilters} />)}
		>
			<BoldText>{i18n.t('filter')}</BoldText>
		</FilterButtonContainer>
	);
};

type Props = {
	isResource?: boolean;
}

const FilterBuilder: React.FC<Props> = ({ isResource }) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { availableFilters } = isResource ? useSearchAndFilterResources() : useSearchAndFilter();

	return <Filter availableFilters={availableFilters} />
}

export default FilterBuilder;
