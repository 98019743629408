import styled from "styled-components";

const SelectWorksiteContainer = styled.div`
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export default SelectWorksiteContainer;