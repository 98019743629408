import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import * as R from 'ramda';

import { getRefByTask } from 'api/routes/referential';
import { CategoryType } from 'api/types/category';
import { ReferentialResponse } from 'api/types/ref';
import { FeaturesFlag, hasFlags } from 'api/utils';
import Button, { Type as ButtonType } from 'components/Button';
import { useModal } from 'components/Modal';
import ModalHeader from 'components/Modal/ModalHeader';
import { ModalContainer } from 'components/Modal/style';
import Search, { SearchType } from 'components/Search';
import { useSearch } from 'components/Search/useSearch';
import { TaskPreferences, usePreferences } from 'providers/preferences';
import i18n from 'utils/lang';
import { Favs, RefsSelection } from 'widgets/Report/components/Modal';
import { EOTPAccordion } from 'widgets/Report/components/Modal/EOTPAccordion';
import {
	ButtonContainer
} from 'widgets/Report/components/Modal/style';
import { SelectableReferential } from 'widgets/Report/components/Modal/type';
import { useClient } from 'providers/client';

type Props = {
	category: CategoryType;
	favTasks: ReferentialResponse[];
	setFavTasks: React.Dispatch<React.SetStateAction<ReferentialResponse[]>>;
	openedFromFavorites?: boolean;
};

const Modal: React.FC<Props> = ({ category, favTasks, setFavTasks, openedFromFavorites = false }) => {
	const modalRef = useRef() as React.MutableRefObject<HTMLDivElement>;
	const { pop } = useModal();
	const { clientHandler: { referentialClient } } = useClient();

	useOnClickOutside(modalRef, pop);

	const {
		preferences: { tasks },
		setTaskPreferences,
	} = usePreferences();
	const [fav, setFav] = useState<boolean>(!openedFromFavorites);
	const [eotps, setEotps] = useState<SelectableReferential[]>(favTasks);
	const hasLabelBefore = category && hasFlags(category.features, FeaturesFlag.LabelBefore);

	useEffect(() => {
		// @ts-expect-error This works but somehow tslint detects an error
		const sortEOTPs = R.sortWith<SelectableReferential>([R.descend(R.prop('isSelected')), R.ascend(R.prop('label'))]);

		void (async () => {
			try {
				if (category.refID) {
					const refTaskIDs = favTasks.map((p) => p.id);
					const rvalue = await getRefByTask(referentialClient, category.refID);
					const result = fav
						? rvalue
							.filter((e) => refTaskIDs.some((rt: string | null) => rt !== null && e.id !== null && rt === e.id))
							.map((e) => ({
								...e,
								isSelected: true,
							}))
						: rvalue;
					setEotps(
						sortEOTPs(
							result.map((e) => ({
								...e,
								isSelected: favTasks.some((t) => t.id === e.id),
								title: hasLabelBefore
									? [e.label, e.id].filter(Boolean).join(' - ')
									: [e.id, e.label].filter(Boolean).join(' - '),
							})),
						),
					);
				}
			} catch (err) {
				console.error(err);
			}
		})();
	}, [category, fav]);

	const create = (): void => {
		const filteredTasks: TaskPreferences = {
			categoryID: category._id,
			refs: eotps.filter((e) => e.isSelected).map((e) => ({ id: e.id, label: e.label, checkable: e.checkable })),
		};
		const prefTasks = tasks ? [...tasks] : [];
		const idxOfCategory = prefTasks.findIndex((t) => t.categoryID === category._id);

		if (idxOfCategory !== -1) {
			prefTasks[idxOfCategory] = filteredTasks;
		} else {
			prefTasks.push(filteredTasks);
		}

		setTaskPreferences(prefTasks);
		setFavTasks(filteredTasks.refs);
		pop();
	};

	const {
		searchValue,
		handleSearchChange,
		setSearchValue,
		filteredItems: filteredEOTPs,
	} = useSearch<SelectableReferential>(eotps, ['label', 'title'], true);
	const nbOfCheckedEotp = useMemo(() => {
		return eotps.filter((e) => e.isSelected).length;
	}, [eotps])

	return (
		<ModalContainer ref={modalRef}>
			<ModalHeader title={i18n.t('add_a_title', { title: category.name })} pop={pop} />
			<Search type={SearchType.FullScreen} value={searchValue} onChange={handleSearchChange} clearText={() => setSearchValue('')} />
			{!openedFromFavorites && <Favs fav={fav} setFav={setFav} />}
			{category.name === 'EOTP' &&
				<EOTPAccordion refs={eotps} filteredRefs={filteredEOTPs} setRefs={setEotps} />
			}
			{category.name !== 'EOTP' &&
				<RefsSelection refs={eotps} filteredRefs={filteredEOTPs} setRefs={setEotps} />
			}
			<ButtonContainer>
				<Button type={ButtonType.modalValidator} onClick={create}>
					{i18n.t('validate')} ({nbOfCheckedEotp})
				</Button>
			</ButtonContainer>
		</ModalContainer>
	);
};

export default Modal;
