import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;

	align-items: center;
	padding: 0px;
	margin: 0px 2px;

	width: 100%;
	height: 100%;

	background: #f2f2f2;
	border: 1px solid black;

	min-width: 200px;

	/* Media query for iPad Air and iPad Pro in landscape mode */
	@media only screen and (min-device-width: 820px) and (max-device-width: 1024px) {
		min-width: 320px;
	}

	&:first-child {
		border-radius: 8px 0 0 8px;
	}

	&:last-child {
		border-radius: 0 8px 8px 0;
	}
`;

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 13px;
	overflow-y: auto;
`;

export type HeaderProps = {
	isSelected: boolean;
};

export const HeaderContainer = styled.div<HeaderProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid black;
	font-weight: bold;
	margin-right: auto;

	width: 100%;
	min-height: 50px;
	background-color: ${({ isSelected }) => (isSelected ? '#25465F' : 'transparent')};
	color: ${({ isSelected }) => (isSelected ? 'white' : 'black')};
`;

export const Padding = styled.div`
	padding-right: 15px;
	padding-left: 25px;
	margin-left: auto;
`;

export const MarginLeftAuto = styled.div`
	margin-right: -30px;
	margin-left: auto;
	padding-left: 25px;
`;

export default Container;
