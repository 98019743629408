import React, { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Home from 'pages/Home';
import Report from 'pages/Report';
import Setting from 'pages/Setting';
import Admin from 'pages/Admin';
import Links from 'pages/Links';
import Navbar from 'widgets/Navbar';
import Footer from 'widgets/Footer';
import { ModalProvider, useModal } from 'components/Modal';
import { UserProvider } from 'providers/user';
import { PrefProvider } from 'providers/preferences';
import { ReportsProvider } from 'providers/ReportsProvider';
import { ExportServicesProvider } from 'providers/ExportServicesProvider';
import { ClientProvider } from 'providers/client';
import { useKeycloak } from 'providers/keycloak';
import Worksite from 'widgets/Worksite';
import routes from 'utils/routes';
import SelectWorksite from 'components/Modal/SelectWorksite';
import SelectWorksiteContainer from 'components/SelectWorksite/style';
import Loader, { LoadingType } from 'components/Loader';

import Container, { LayoutContainer } from '../style';

type Props = {
	admin?: boolean;
};

const CustomLayoutWithChildren: React.FC<React.PropsWithChildren<Props>> = ({ children, admin = false }) => {
	const { pop } = useModal();
	const location = useLocation();

	useEffect(() => {
		pop();
	}, [location, pop]);

	return (
		<ModalProvider>
			<LayoutContainer>
				<Navbar admin={admin} />
				<Container>{children}</Container>
				<Footer />
			</LayoutContainer>
			<ToastContainer />
		</ModalProvider>
	);
};

const router = createBrowserRouter([
	{
		path: routes.home,
		element: (
			<CustomLayoutWithChildren>
				<Home />
			</CustomLayoutWithChildren>
		),
	},
	{
		path: routes.links,
		element: (
			<CustomLayoutWithChildren>
				<Links />
			</CustomLayoutWithChildren>
		),
	},
	{
		path: `${routes.report}/:date`,
		element: (
			<CustomLayoutWithChildren>
				<Report />
			</CustomLayoutWithChildren>
		),
	},
	{
		path: routes.settings,
		element: (
			<CustomLayoutWithChildren>
				<Setting />
			</CustomLayoutWithChildren>
		),
	},
	{
		path: routes.admin,
		element: (
			<CustomLayoutWithChildren admin>
				<Admin />
			</CustomLayoutWithChildren>
		),
	},
	{
		path: `${routes.worksite}/:code`,
		element: (
			<Worksite />
		),
	},
	{
		path: routes.worksite_list,
		element: (
			<SelectWorksiteContainer>
				<SelectWorksite isFullPage />
			</SelectWorksiteContainer>
		)
	},
	{
		path: '*',
		element: <div>NOT FOUND</div>
	}
]);

const AuthRouter: React.FC = () => {
	const { clientId, keycloak } = useKeycloak();
	const [hasLngChanged, changeLng] = useState(undefined as Date | undefined);

	useEffect(() => {
		// trigger refresh on language change
		// Temporary solution to force refresh when language changed
		// Fix previous bug where translations in navbar were not made on page refresh
	}, [hasLngChanged]);

	if (!clientId || !keycloak) return (
		<Loader type={LoadingType.Fullscreen} />
	);

	return (
		<ClientProvider>
			<UserProvider>
				<PrefProvider changeLng={changeLng}>
					<ReportsProvider>
						<ExportServicesProvider>
							<RouterProvider router={router} />
						</ExportServicesProvider>
					</ReportsProvider>
				</PrefProvider>
			</UserProvider>
		</ClientProvider>
	);
};

export default AuthRouter;