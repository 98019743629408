import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { addDays, format, startOfWeek, subDays } from 'date-fns';

import { Flex } from 'styles';
import ArrowLeft from 'assets/svg/ArrowLeft';
import ArrowRight from 'assets/svg/ArrowRight';
import Menu from 'assets/svg/Menu';
import Button, { Type as ButtonType } from 'components/Button';
import Dropdown, { DropdownType } from 'components/Dropdown';
import i18n, { getLocaleFromCode } from 'utils/lang';
import { ExportModal } from 'components/ExportModal';
import LockReportModal from 'components/LockReportModal';
import { useModal } from 'components/Modal';
import { usePreferences } from 'providers/preferences';
import ExportChart from 'assets/svg/ExportChart';
import ArrowDown from 'assets/svg/ArrowDown';
import { useUser } from 'providers/user';
import Lock from 'assets/svg/Lock';

import { DropdownButton, DropdownMenu } from '../../../Navbar/style';
import Container, { Content, MenuContainer, NoHeight } from './style';

type CalendarHeaderProps = {
	currentDate: Date;
	setCurrentDate: (date: Date) => void;
	refreshReport: () => void;
};

const CalendarHeader: React.FC<CalendarHeaderProps> = ({ currentDate, setCurrentDate, refreshReport }) => {
	const user = useUser();
	const { push } = useModal();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { preferences } = usePreferences()

	const handleChange = (date: Date | null): void => {
		setIsOpen(!isOpen);

		setCurrentDate(startOfWeek(date ?? Date.now(), { locale: getLocaleFromCode(preferences.language) }));
	};

	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		e.preventDefault();
		setIsOpen(!isOpen);
	};

	const resetIndex = (): void => {
		setCurrentDate(startOfWeek(Date.now(), { locale: getLocaleFromCode(preferences.language) }));
	};

	return (
		<Container>
			<Content>
				<Button type={ButtonType.secondary} onClick={resetIndex}>
					{i18n.t('today')}
				</Button>
				<Button type={ButtonType.calendarHeader} onClick={() => setCurrentDate(startOfWeek(subDays(currentDate, 7), { locale: getLocaleFromCode(preferences.language) }))}>
					<ArrowLeft />
				</Button>
				<Button type={ButtonType.calendarHeader} onClick={() => setCurrentDate(startOfWeek(addDays(currentDate, 7), { locale: getLocaleFromCode(preferences.language) }))}>
					<ArrowRight />
				</Button>
				<Flex flexDirection='column'>
					<Dropdown
						type={DropdownType.Menu}
						title={format(currentDate, 'MMMM yyyy', { locale: getLocaleFromCode(preferences.language) })}
						titleComponent={<ArrowDown color='#000000' />}
						color="#000000"
						onClick={handleClick}
					/>
					<NoHeight>
						{isOpen && <DatePicker locale={getLocaleFromCode(preferences.language)} selected={currentDate} onChange={handleChange} inline />}
					</NoHeight>
				</Flex>
				<MenuContainer>
					<Dropdown titleComponent={<Menu />}>
						<DropdownMenu>
							<DropdownButton onClick={() => push(<ExportModal />)}>
								<span>{i18n.t('export')}</span>
								<ExportChart />
							</DropdownButton>
							{user?.isLocker && (
								<DropdownButton onClick={() => push(<LockReportModal refreshReports={refreshReport} />)}>
									<span>{i18n.t('lock_reports')}</span>
									<Lock color="#000" size="22" />
								</DropdownButton>
							)}
						</DropdownMenu>
					</Dropdown>
				</MenuContainer>
			</Content>
		</Container>
	);
};

export default CalendarHeader;
