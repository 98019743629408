import React, { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { add, format, parse, parseISO, startOfMonth, sub } from 'date-fns';
import i18n from 'i18next';

import { userWithReportList } from 'api/routes/user';
import ModalHeader from 'components/Modal/ModalHeader';
import { ModalContainer } from 'components/Modal/style';
import { showError } from 'utils/error';
import { useClient } from 'providers/client';
import { Flex, Margin } from 'styles';
import Checkbox, { State } from 'components/Checkbox';
import { BoldSpan } from 'widgets/Report/components/Modal/style';
import { lockReportByDates } from 'api/routes/report';
import { useLocalStorageState } from 'utils/common';
import { useKeycloak } from 'providers/keycloak';
import { dateBaseOnSettings } from 'components/ExportModal';

import { useModal } from '../Modal';
import {
	DateInput,
	DateSelection,
	ModalBody,
	Separator,
	UserListContainer,
	ValidateButton,
} from '../ExportModal/styles';
import { toast } from 'react-toastify';

type Props = {
	refreshReports: () => void;
}

const LockReportModal: React.FC<Props> = ({ refreshReports }) => {
	const { keycloak } = useKeycloak();
	const { clientHandler: { client }, worksite } = useClient();
	const { pop } = useModal();
	const modalRef = useRef() as React.MutableRefObject<HTMLInputElement>;

	const [currentlyViewedDate] = useLocalStorageState<Date>('homepage-calendar', new Date(), keycloak, (value) => parseISO(value));

	const datesFromWorksite = dateBaseOnSettings(worksite.settings, currentlyViewedDate);
	const [users, setUsers] = useState<UserResponse[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
	const [selectAll, setSelectAll] = useState<boolean>(true);

	const [startDate, setStartDate] = useState<Date>(
		datesFromWorksite?.startDate ??
		sub(add(startOfMonth(currentlyViewedDate), { days: 15 }), { months: 1 }),
	);
	const [endDate, setEndDate] = useState<Date>(
		datesFromWorksite?.endDate ??
		add(startOfMonth(currentlyViewedDate), { days: 14 })
	);

	const handleCloseModal = (): void => {
		pop();
	};

	useEffect(() => {
		const fetchUserList = async (): Promise<void> => {
			const lists = await userWithReportList(client, new Date(format(startDate, 'yyyy-MM-dd')), new Date(format(endDate, 'yyyy-MM-dd')));
			const filteredUsers = lists.sort((a, b) => (a.displayName ?? a.userName).localeCompare(b.displayName ?? b.userName));

			setUsers(filteredUsers);
			setSelectedUsers(filteredUsers.map((e) => e.userName));
		};
		fetchUserList().catch(showError('Unable to fetch users'));
	}, [startDate, endDate]);

	useOnClickOutside(modalRef, handleCloseModal);

	return (
		<ModalContainer ref={modalRef} customHeight='auto' maxWidth="60rem">
			<ModalHeader title={i18n.t('lock_reports')} pop={handleCloseModal} />
			<ModalBody onSubmit={(e) => e.preventDefault()}>
				<h3 style={{ margin: 0 }}>{i18n.t('period_choice')}</h3>
				<DateSelection>
					<span className="fs-14">{i18n.t('from')}</span>
					<DateInput
						type="date"
						value={format(startDate, 'yyyy-MM-dd')}
						onChange={(e) => setStartDate(parse(e.target.value, 'yyyy-MM-dd', new Date()))}
					/>
					<span className="fs-14">{i18n.t('to')}</span>
					<DateInput
						type="date"
						value={format(endDate, 'yyyy-MM-dd')}
						onChange={(e) => setEndDate(parse(e.target.value, 'yyyy-MM-dd', new Date()))}
					/>
				</DateSelection>

				<h3 style={{ margin: 0 }}>{i18n.t('select_scope')}</h3>
				<UserListContainer>
					<Flex className="report-modal" flexDirection="row">
						<Checkbox state={users.length === selectedUsers.length ? State.Checked : State.Unchecked} onClick={() => {
							const newSelectState = !selectAll;

							setSelectedUsers(newSelectState ? users.map((e) => e.userName) : []);
							setSelectAll(newSelectState);
						}} />
						<Margin left={16} centerContent>
							<BoldSpan>{i18n.t('all_scope')}</BoldSpan>
						</Margin>
					</Flex>
					<Separator />
					{users
						.map((u, idx) => (
							<Flex key={`${u.userName}-${idx}`} className="report-modal" flexDirection="row">
								<Checkbox state={selectedUsers.includes(u.userName) ? State.Checked : State.Unchecked} onClick={() => {
									setSelectedUsers(
										selectedUsers.includes(u.userName)
											? selectedUsers.filter((e) => u.userName !== e)
											: [...selectedUsers, u.userName]
									);
								}} />
								<Margin left={16} centerContent>
									<BoldSpan>{u.displayName === '' ? u.userName : u.displayName}</BoldSpan>
								</Margin>
							</Flex>
						))}
				</UserListContainer>

				<ValidateButton
					type="submit"
					disabled={selectedUsers.length === 0}
					className="fs-20"
					onClick={() => {
						lockReportByDates(client, { 
							startDate: format(startDate, 'yyyy-MM-dd'), 
							endDate: format(endDate, 'yyyy-MM-dd'), 
							users: selectedUsers.join(',')
						}).then((nbOfLocks) => {
							if (nbOfLocks !== 0) {
								toast.info(i18n.t(`nb_of_lock_reports`, { nb: nbOfLocks }));
								refreshReports();
							} else {
								toast.info(i18n.t(`nothing_lock_reports`));
							}

							pop();
						}).catch(showError);
					}}
				>
					{i18n.t('validate')}
				</ValidateButton>
			</ModalBody>
		</ModalContainer>
	);
};


export default LockReportModal;