import React, { useState, useEffect } from "react";
import { useDebounce } from "usehooks-ts";

import i18n from "utils/lang";
import { ReportType } from "api/types/report";
import { showError } from "utils/error";

import Container, { TemperatureInput, BolderText } from "./style";

type Props = {
    temperature: {
        min?: number;
        max?: number;
    };
    updateReport: <K extends keyof ReportType>(key: K, value: ReportType[K]) => Promise<void>;
}

const Temperature: React.FC<Props> = ({ temperature, updateReport }) => {
    const [tempMin, setTempMin] = useState<number | undefined>(temperature.min);
    const [tempMax, setTempMax] = useState<number | undefined>(temperature.max);
    const debouncedTempMin = useDebounce(tempMin, 750);
    const debouncedTempMax = useDebounce(tempMax, 750);

    useEffect(() => {
        if (tempMin !== temperature.min) {
            updateReport('tempMin', tempMin).catch(showError("Unable to update report:minTemp"));
        }
    }, [debouncedTempMin]);

    useEffect(() => {
        if (tempMax !== temperature.max) {
            updateReport('tempMax', tempMax).catch(showError("Unable to update report:minTemp"));
        }
    }, [debouncedTempMax]);

    return (
        <Container>
            <div>
                <span>{i18n.t('daily_temperature')}</span>
            </div>
            <div>
                <TemperatureInput
                    type="number"
                    placeholder={i18n.t("input.placeholder.min") || ""}
                    value={tempMin ?? ''}
                    onKeyDown={(event) => event.code === "KeyE" && event.preventDefault() }
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (!Number.isNaN(e.currentTarget.valueAsNumber)) {
                            setTempMin(e.currentTarget.valueAsNumber);
                        }
                    }}
                />
                <BolderText>°C</BolderText>
            </div>
            <div>
                <TemperatureInput
                    type="number"
                    placeholder={i18n.t("input.placeholder.max") || ""}
                    value={tempMax ?? ''}
                    onKeyDown={(event) => event.code === "KeyE" && event.preventDefault() }
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (!Number.isNaN(e.currentTarget.valueAsNumber)) {
                            setTempMax(e.currentTarget.valueAsNumber);
                        }
                    }}
                />
                <BolderText>°C</BolderText>
            </div>
        </Container>
    )
}

export default Temperature;