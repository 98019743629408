import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import * as dateFns from 'date-fns';

import { ReportListPayload, ReportResponse } from '../types';
import { ReportStatus, ReportType } from '../types/report';
import type { ApiResponse } from './type';

export const reportList = async (client: AxiosInstance, { startDate, endDate, createdBy }: ReportListPayload): Promise<ReportResponse[]> => {
	const res: AxiosResponse<ApiResponse<ReportResponse[]>> = await client.get('report', {
		params: {
			startDate: startDate,
			endDate: endDate,
			createdBy: createdBy
		}
	});

	return res.data?.data;
};

export const reportAdd = async (client: AxiosInstance, data: ReportType): Promise<ReportResponse | null> => {
	const res = await client.post<ReportType, AxiosResponse<{ message: string; data: ReportResponse }>>('report', data);

	return res.data?.data ?? null;
};

export const reportDuplicate = async (client: AxiosInstance, reportId: number, dateTo: Date): Promise<ReportResponse | null> => {
	try {
		const res = await client.put<ReportType, AxiosResponse<{ message: string; data: ReportResponse }>>(
			`report/${reportId}/duplicate`,
			{ dateTo: dateFns.format(dateTo, 'yyyy-MM-dd') },
		);

		if (res instanceof AxiosError && res.response?.status === 409) {
			return (res.response as AxiosResponse<{ message: string; data: ReportResponse }>).data?.data ?? null;
		}

		return res.data?.data ?? null;
	} catch (err) {
		return null;
	}
};

export const reportUpdate = async (client: AxiosInstance, data: ReportType): Promise<ReportResponse | null> => {
	const res = await client.put<ReportType, AxiosResponse<{ message: string; data: ReportResponse }>>(
		`report/${data._id ?? ''}`,
		data,
	);

	return res.data?.data ?? null;
};

export const reportDelete = async (client: AxiosInstance, reportId: string): Promise<void> => {
	await client.delete(`report/${reportId}`);
};

export const validateReport = async (client: AxiosInstance, reportId: string): Promise<ReportStatus> => {
	await client.put(`report/${reportId}/validate`);
	return ReportStatus.Validated;
};
export const invalidateReport = async (client: AxiosInstance, reportId: string): Promise<ReportStatus> => {
	await client.put(`report/${reportId}/invalidate`);
	return ReportStatus.Invalidated;
};

export const checkReport = async (client: AxiosInstance, reportId: string): Promise<ReportStatus> => {
	await client.put(`report/${reportId}/check`);
	return ReportStatus.Checked;
};

export const uncheckReport = async (client: AxiosInstance, reportId: string): Promise<ReportStatus> => {
	await client.put(`report/${reportId}/uncheck`);
	return ReportStatus.Validated;
};

export const lockReport = async (client: AxiosInstance, reportId: string): Promise<ReportStatus> => {
	await client.put(`report/${reportId}/lock`);
	return ReportStatus.Locked;
};

export type ReportLockByDatesBody = {
	startDate: string;
	endDate: string;
	users: string;
}

export const lockReportByDates = async (client: AxiosInstance, body: ReportLockByDatesBody): Promise<number> => {
	const res = await client.post<{ data: number; }>(`report/lockByDates`, body);

	if (res.status === 204) return 0;
	return res.data.data;
}

export const unlockReport = async (client: AxiosInstance, reportId: string): Promise<ReportStatus> => {
	await client.put(`report/${reportId}/unlock`);
	return ReportStatus.Checked;
};
