import React, { useMemo, useRef, useState } from 'react';
import { useEffectOnce, useOnClickOutside } from 'usehooks-ts';
import styled from 'styled-components';
import * as R from 'ramda';

import { getRefByTask } from 'api/routes/referential';
import { FeaturesFlag, hasFlags } from 'api/utils';
import Button, { Type as ButtonType } from 'components/Button';
import { useModal } from 'components/Modal';
import ModalHeader from 'components/Modal/ModalHeader';
import Search, { SearchType } from 'components/Search';
import { useSearch } from 'components/Search/useSearch';
import { EOTPCategory } from 'utils/constants';
import i18n from 'utils/lang';
import { EOTPAccordion } from 'widgets/Report/components/Modal/EOTPAccordion';
import Container, {
	ButtonContainer,
} from 'widgets/Report/components/Modal/style';
import { SelectableReferential } from 'widgets/Report/components/Modal/type';
import { useClient } from 'providers/client';

import EOTPGroupRadioSelect from './EOTPGroupRadioSelect';

const AdminModalContainer = styled(Container)`
	box-sizing: border-box;
	padding: 2em 3em;

	& > * {
		box-sizing: border-box;
	}

	.w-full {
		width: 100%;
	}
`;

const AdminModalButtonContainer = styled(ButtonContainer)`
	button {
		margin: 0 !important;
	}
`;

type AdminAddEotpModalProps = {
	addEotp: (id: string, label: string, group: string | null) => unknown;
	groups: [string, ...string[]];
};

export const AdminAddEotpModal: React.FC<AdminAddEotpModalProps> = ({ addEotp, groups }) => {
	const { clientHandler: { referentialClient } } = useClient();
	const modalRef = useRef() as React.MutableRefObject<HTMLDivElement>;
	const { pop } = useModal();

	useOnClickOutside(modalRef, pop);

	const availableGroups = useMemo(() => groups.filter((g) => g !== i18n.t('custom')), [groups]);
	const [eotps, setEotps] = useState<SelectableReferential[]>([]);
	const [group, setGroup] = useState<string>(availableGroups[0]);

	useEffectOnce(() => {
		const hasLabelBefore = hasFlags(EOTPCategory.features, FeaturesFlag.LabelBefore);

		const sortEOTPs = R.sortWith<SelectableReferential>([
			R.descend(R.prop<string>('isSelected')),
			R.ascend(R.prop<string>('label')),
		]);

		(async () => {
			const rvalue = await getRefByTask(referentialClient, EOTPCategory.refID ?? '');
			setEotps(
				sortEOTPs(
					rvalue.map((e) => ({
						...e,
						isSelected: false,
						title: hasLabelBefore
							? [e.label, e.id].filter(Boolean).join(' - ')
							: [e.id, e.label].filter(Boolean).join(' - '),
					})),
				),
			);
		})().catch(console.error);
	});

	const {
		searchValue,
		handleSearchChange,
		setSearchValue,
		filteredItems: filteredEOTPs,
	} = useSearch<SelectableReferential>(eotps, ['label', 'title'], true);

	const handleAddEotps = (): void => {
		const eotpsToAdd = eotps.filter(R.prop('isSelected'));

		for (const eotpToAdd of eotpsToAdd) {
			addEotp(eotpToAdd.id, eotpToAdd.label ?? '', group);
		}

		pop();
	};

	const selectedAndFilteredRefs = useMemo(() => {
		const selectedRefs = eotps.filter((ref) => ref.isSelected);
		return R.uniq([...filteredEOTPs, ...selectedRefs]);
	}, [eotps, filteredEOTPs]);

	return (
		<AdminModalContainer flexDirection="column" ref={modalRef}>
			<ModalHeader title={i18n.t('add_a_title', { title: 'EOTP' })} pop={pop} />
			<Search value={searchValue} onChange={handleSearchChange} type={SearchType.FullWidth} clearText={() => setSearchValue('')} />
			<div className="w-full">
				<EOTPGroupRadioSelect groups={availableGroups} activeGroup={group} onGroupChange={setGroup} />
			</div>
			<EOTPAccordion refs={eotps} filteredRefs={selectedAndFilteredRefs} setRefs={setEotps} />
			<AdminModalButtonContainer>
				<Button type={ButtonType.modalValidator} onClick={handleAddEotps}>
					{i18n.t('validate')}
				</Button>
			</AdminModalButtonContainer>
		</AdminModalContainer>
	);
};
