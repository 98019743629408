import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import Keycloak, { KeycloakConfig } from 'keycloak-js';

import { useLocalStorageState } from 'utils/common';
import { showError } from 'utils/error';
import { setPendingRequests } from 'utils/pendingRequests';
import { unauthClient } from 'api/client';
import { getAllWorksiteUnauth, WorksiteResponseUnauth } from 'api/routes/worksite';

type KeycloakServices = {
    keycloak?: Keycloak;
    clientId?: string;
    roleByWorksite: RolesByWorksite[];
    setClientId: React.Dispatch<React.SetStateAction<string | undefined>>;
    setKeycloak: React.Dispatch<React.SetStateAction<Keycloak | undefined>>;
    generateKeycloakClient: () => Promise<void>;
};

export type RolesByWorksite = {
    worksite: string;
    unauthData?: WorksiteResponseUnauth,
    roles: string[]
}

const KeycloakServicesContext = createContext<KeycloakServices>({
    roleByWorksite: [],
    setClientId: () => { },
    setKeycloak: () => { },
    generateKeycloakClient: () => Promise.resolve(),
});

export const createKeycloakClient = (clientId: string): Keycloak => {
    const initOptions: KeycloakConfig = {
        url: process.env.REACT_APP_KEYCLOAK_URL,
        realm: process.env.REACT_APP_KEYCLOAK_REALM || '',
        clientId,
    };
    const kc = new Keycloak(initOptions);

    kc.clientSecret = process.env.REACT_APP_KEYCLOAK_SECRET;
    return kc;
}

export const KeycloakProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [clientId, setClientId] = useLocalStorageState<string | undefined>('clientId', undefined);
    const [roleByWorksite, setRoleByWorksite] = useState<RolesByWorksite[]>([]);
    const [keycloak, setKeycloak] = useState<Keycloak>();

    const generateKeycloakClient = async (): Promise<void> => {
        if (!clientId) return;
        setPendingRequests(0);

        const kc = createKeycloakClient(clientId);
        const authenticated = await kc
            .init({
                onLoad: 'login-required',
                silentCheckSsoRedirectUri: window.location.origin + '/',
                checkLoginIframe: false,
                checkLoginIframeInterval: 5,
            });
        if (!authenticated || !kc.token) {
            throw Error('User is not authenticated !');
        }
        const worksitesResults = await getAllWorksiteUnauth(unauthClient)
        const worksites = worksitesResults.map((data) => data.keycloak_client);
        const rbw = Object.entries(kc.resourceAccess ?? {})
            .map(([worksite, { roles }]) => ({ worksite, roles, unauthData: worksitesResults.find((e) => e.keycloak_client === worksite) }))
            .filter((e) => worksites.includes(e.worksite));

        setRoleByWorksite(rbw);
        setKeycloak(kc);
    }

    useEffect(() => {
        (async () => {
            await generateKeycloakClient();
        })().catch((err) => {
            showError(`Error: keycloak cannot be intitialized`)(new Error(err as string));
            setClientId(undefined);
        });
    }, [clientId]);


    return (
        <KeycloakServicesContext.Provider
            value={{
                keycloak,
                clientId,
                roleByWorksite,
                setClientId,
                setKeycloak,
                generateKeycloakClient,
            }}
        >
            {children}
        </KeycloakServicesContext.Provider>
    );
};

export const useKeycloak: () => KeycloakServices = () => useContext(KeycloakServicesContext);
