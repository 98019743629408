import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow-y: auto;
	height: 100%;
`;

export const WeekContainer = styled.div`
	display: flex;
	flex-direction: row;
	padding: 24px 14px;
	// 100% of the parent - self padding - CalendarHeader max-height 
	// to keep maximal theorical height without calculing in js
	height: calc(100% - 148px);
	overflow-x: auto;
`;

export const ContentPadding = styled.div`
	padding: 5px 0;
`;

export default Container;
