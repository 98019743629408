import React, { useRef } from 'react';
import { useModal } from 'components/Modal';
import { useOnClickOutside } from 'usehooks-ts';
import Cross from 'assets/svg/Cross';
import i18n from 'i18next';

import { ModalActions, ModalBody, ModalContent, ModalHeader } from './style';

type ValidationModalProps = {
	execOnConfirm: () => void;
    text: string;
};

const ValidationModal: React.FC<ValidationModalProps> = ({ execOnConfirm, text }) => {
	const { pop } = useModal();
	const modalRef = useRef() as React.MutableRefObject<HTMLDivElement>;

	useOnClickOutside(modalRef, pop);

	return (
		<ModalBody ref={modalRef}>
			<ModalHeader>
				<div onClick={pop}>
					<Cross />
				</div>
			</ModalHeader>
			<ModalContent>
				<p className="content">{text}</p>
				<ModalActions>
					<button className="cancel" onClick={pop}>{i18n.t('confirm_negative')}</button>
					<button className="confirm" onClick={execOnConfirm}>
						{i18n.t('confirm_affirmative')}
					</button>
				</ModalActions>
			</ModalContent>
		</ModalBody>
	);
};

export default React.memo(ValidationModal);
