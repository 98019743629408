import React, { useRef } from 'react';

import ArrowDown from 'assets/svg/ArrowDown';

import Container, { MenuWrapper, TitlePadding } from './style';
import { useOnClickOutside, useToggle } from 'usehooks-ts';
import SVGCalendar from 'assets/svg/Calendar';

export enum DropdownType {
	Default,
	Calendar,
	Disabled,
	Menu,
	Modal,
	Filter
}

export type Props = {
	title?: string;
	titleComponent?: React.ReactNode;
	color?: string;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	type?: DropdownType;
	notInteractive?: boolean;
};

const Dropdown: React.FC<React.PropsWithChildren<Props>> = ({ title, titleComponent, color, onClick, children, type, notInteractive = false }) => {
	const [open, toggle] = useToggle();
	const buttonRef = useRef() as React.MutableRefObject<HTMLInputElement>;

	function handleContainerClick(e: React.MouseEvent<HTMLDivElement>): void {
		toggle();
		if (onClick) {
			onClick(e);
		}
	}

	useOnClickOutside(buttonRef, () => {
		if (type === DropdownType.Filter) return;
		if (open) {
			toggle();
		}
	});

	return (
		<Container
			className={type === DropdownType.Modal && open ? 'dropdown-isOpen' : 'dropdown-notOpen'}
			color={color}
			onClick={handleContainerClick}
			ref={buttonRef}
			type={type}
			notInteractive={notInteractive}
		>
			{type === DropdownType.Calendar && <SVGCalendar />}
			{title != null && <TitlePadding>{title}</TitlePadding>}
			{titleComponent != null && ((type === DropdownType.Menu) ? titleComponent : <TitlePadding>{titleComponent}</TitlePadding>)}
			{type === DropdownType.Default && titleComponent == null && <ArrowDown color={color} />}

			{open && type !== DropdownType.Disabled && <MenuWrapper type={type}>{children}</MenuWrapper>}
		</Container>
	);
};

export default Dropdown;
