import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding: 10px;
	width: 160px;
	

	background: #ffffff;
	border-radius: 8px;
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);

	cursor: pointer;

	&[data-disabled='true'] {
		cursor: not-allowed;
		filter: opacity(0.5);
	}
`;

export const NameContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-items: flex-start;
	gap: 8px;
	text-align: center;
	color: #000000;
	flex-grow: 1;

	div {
		text-align: left;
	}
`;

export const ReportName = styled.b`
	font-size: 14px;
	font-weight: 700;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const ReportAuthorName = styled.span`
	font-size: 12px;
	font-weight: 400;
`;

export const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
`;

export default Container;
