import styled from 'styled-components';

import { Flex, Margin } from 'styles';
import { ContentContainerType, FavType } from './Enums';


const Container = styled(Flex)`
	padding: 32px 60px;
	gap: 16px;

	width: 80%;
	height: 75%;
	max-width: 1439px;
	max-height: 690px;

	background: #f2f2f2;
	border-radius: 8px;
`;


export const Subtitle = styled.b`
	font-size: 14px;
	font-weight: 400;
	text-align: center;
	line-height: 16.41px;
`;

export const AddRessourceContainer = styled(Flex)`
	width: 45%;
	gap: 12px;
`;

type ContentContainerProps = {
	type?: ContentContainerType;
}

export const ContentContainer = styled(Flex) <ContentContainerProps>`
	width: 100%;
	background-color: #ffffff;
	border-radius: 4px;
	${({ type }) =>
		!type &&
		`
		min-height: 200px;
		flex-grow: 1;
	`}
	${({ type }) =>
		type &&
		type === ContentContainerType.InputUnits &&
		`
		min-height: min-content;
	`}
`;

export const UnitTitle = styled.div`
	font-weight: 700;
	font-size: 12px;
	color: rgba(0, 0, 0, 0.5);
`;

export const ButtonContainer = styled.div`
	align-self: flex-end;
`;

export const BoldSpan = styled.span`
	display: flex;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	font-weight: bold;
	width: max-content;
`;

export const SourcesListContainer = styled(Flex)`
	overflow-x: auto;
	font-size: 14px;
`;

export const SourceContainer = styled(Margin)`
	display: inline-block;
`;

export const SelectionContainer = styled(Flex)`
	height: 100%;
	overflow-y: auto;
	margin: 8px;

	.report-modal {
		border-bottom: 1px solid #25465f66;
		padding: 12px 0;
	}

	.report-modal:last-child {
		border-bottom: none;
	}
`;

export const EOTPSelectionContainer = styled(Flex)`
	height: 100%;
	overflow-y: auto;

	.report-modal {
		padding: 12px 0;
	}
`;

type FavContainerProps = {
	type?: FavType;
}

export const FavContainer = styled(Flex) <FavContainerProps>`
	justify-content: start;
	flex-direction: row;
	align-items: center;

	${({ type }) =>
		type &&
		type === FavType.ResourceModal &&
		`
		width: 25%;
		justify-content: center;
	`}
`

export const CursorPointerFlex = styled(Flex)`
	cursor: pointer;
	width: 24px;
`;

export const DisplayDropDownOnHover = styled(Flex)`
  .dropdown-eotp-accordion .dropdown-notOpen {
    opacity: 0;
  }

  &:hover .dropdown-eotp-accordion .dropdown-notOpen {
    opacity: 1;
  }

  .dropdown-eotp-accordion .dropdown-isOpen {
    opacity: 1 !important;
  }

  &:hover .dropdown-eotp-accordion .dropdown-isOpen {
    opacity: 1;
  }
`;

export default Container;
