import styled from 'styled-components';

import { Flex } from 'styles';

const Container = styled(Flex)`
	flex-direction: row;
	width: 100%;
`;

export const FilterButtonContainer = styled(Flex)`
	border: 2px solid #25465F;
	border-radius: .25rem;
	padding: 6px 8px;
	margin-right: 20px;
	cursor: pointer;
`;

export const FilterModalTextFilterContainer = styled.div`
	margin: 10px 0;
`;

export const FilterTitle = styled.b`
	width: max-content;
	text-align: center;
	margin-left: 5px;
`;

export const FilterContainer = styled(Flex)`
	flex-direction: row;
	overflow-x: auto;
	gap: 8px;
	margin-left: 8px;
`;

export type SquareProps = {
	color: string;
};

export const Square = styled.div<SquareProps>`
	background-color: ${({ color }) => color};
	width: 16px;
	height: 16px;
`;

export const BoldText = styled.b`
	color: #25465F;
`;

export const WrapperPopUp = styled.div`
    position: absolute;
    top: 30px;
    width: 100px;
`;

export const ContainerPopUp = styled.div`
    background-color: white;
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    padding: 20px;
`;

export default Container;
