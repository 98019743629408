import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getAllWorksiteUnauth, searchCodeWorksite } from "api/routes/worksite";
import { showError } from "utils/error";
import Loader, { LoadingType } from "components/Loader";
import routes from "utils/routes";
import { useKeycloak } from "providers/keycloak";
import { unauthClient } from "api/client";

const UnauthHome: React.FC = () => {
    const { clientId, setClientId } = useKeycloak();
    const navigate = useNavigate();

    useEffect(() => {
        if (!clientId) {
            (async () => {
                const worksites = await getAllWorksiteUnauth(unauthClient).then((e) => {
                    if (!e || e.length < 1) throw Error("Worksite should not be empty");
                    return e;
                });
                const cId = await searchCodeWorksite(unauthClient, worksites[0].code).then((e) => {
                    if (!e.keycloak_client) throw Error("Keycloak client is not found");
                    return e;
                });

                setClientId(cId.keycloak_client);
                navigate(routes.home);
            })().catch(showError('Error when fetching worksites'));
        }
    }, [])

    return <Loader type={LoadingType.Fullscreen} />;
}

export default UnauthHome;