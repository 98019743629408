import * as dateFns from 'date-fns';
import * as R from 'ramda';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { generatePreparedCounter } from 'api/routes/preparedcounter';
import { reportAdd, reportDuplicate } from 'api/routes/report';
import { ReportResponse } from 'api/types';
import { ReportStatus } from 'api/types/report';
import { FeaturesFlag, hasFlags } from 'api/utils';
import Add from 'assets/svg/Add';
import Data from 'assets/svg/Data';
import Button, { Type as ButtonType } from 'components/Button';
import { useModal } from 'components/Modal';
import { useReports } from 'providers/ReportsProvider';
import { usePreferences } from 'providers/preferences';
import { UserRoles, useUser } from 'providers/user';
import { reportResponseToReportType } from 'utils/common';
import { getLocaleFromCode } from 'utils/lang';
import routes from 'utils/routes';
import UserCard, { numIntoUserStatus } from 'widgets/Calendar/components/UserCard';
import { ContentPadding } from 'widgets/Calendar/style';
import { useCategory } from 'widgets/Report/provider/category';
import { useClient } from 'providers/client';
import DuplicateReportModal from '../DuplicateReportModal';
import Container, { ContentContainer, HeaderContainer, MarginLeftAuto, Padding } from './style';

type CalendarDayProps = {
	day: Date;
	reports: ReportResponse[];
	allReports: ReportResponse[];
	hasData: boolean;
};

const CalendarDay: React.FC<CalendarDayProps> = ({ day, reports, allReports, hasData }) => {
	const { clientHandler: { client } } = useClient();
	const { push: pushModal } = useModal();
	const { setCurrentReport, loading } = useReports();

	const navigate = useNavigate();
	const userInfos = useUser();
	const { preferences } = usePreferences();

	const isCurrentDay: boolean = dateFns.isSameDay(day, Date.now());
	const isInPast = dateFns.isBefore(day, Date.now());
	const username = userInfos?.infos?.preferred_username;
	const isUserInReport = !!username && reports.some((e) => e?.createdBy === username);
	const categories = useCategory();
	const isWriter = userInfos?.roles?.includes(UserRoles.Writer);

	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	const sortDayReports = R.sortWith<ReportResponse>([
		// @ts-expect-error Works perfectly but tslint detects an error somehow
		R.descend(R.propEq(username, 'createdBy')),
		// @ts-expect-error Works perfectly but tslint detects an error somehow
		R.descend(R.propEq(ReportStatus.Validated, 'status')),
		// @ts-expect-error Works perfectly but tslint detects an error somehow
		R.ascend(R.prop('createdBy')),
	]);

	const mostRecentUsableReportForCopy = (() => {
		const sevenDaysAgo = dateFns.sub(dateFns.startOfDay(day), { days: 7 });

		const last7DaysCurrentUserReports = allReports.filter((report) => {
			if (!report.createdBy || !report.dateReport) return false;
			if (report.createdBy !== username) return false;
			if ([ReportStatus.Validated, ReportStatus.Checked, ReportStatus.Locked].indexOf(report.status ?? -1) === -1) return false;

			const dayDate = dateFns.startOfDay(new Date(day));
			const reportDate = dateFns.startOfDay(new Date(report.dateReport));

			return (
				dateFns.isAfter(dateFns.add(dayDate, { seconds: 1 }), reportDate) && dateFns.isAfter(reportDate, sevenDaysAgo)
			);
		});

		return (
			last7DaysCurrentUserReports
				.sort((a, b) => (dateFns.isBefore(new Date(a.dateReport ?? ''), new Date(b.dateReport ?? '')) ? 1 : -1))
				.at(0) ?? null
		);
	})();

	const addReport = useCallback(async () => {
		try {
			const formattedDay = dateFns.format(day, 'yyyy-MM-dd');
			const createdReportResponse = await reportAdd(client, {
				editor: userInfos?.infos?.name ?? userInfos?.infos?.preferred_username ?? '',
				name: userInfos?.infos?.name ?? userInfos?.infos?.preferred_username ?? '',
				status: 0,
				description: '',
				dateReport: formattedDay,
				startTime: preferences.startTime ?? 0,
				endTime: preferences.endTime ?? 0,
				weatherAtStart: 0,
				weatherAtEnd: 0,
			});

			const report = reportResponseToReportType(createdReportResponse);
			if (report) {
				await Promise.all(
					categories
						.filter((category) => hasFlags(category.features, FeaturesFlag.GenPreScore))
						.map((category) =>
							generatePreparedCounter(client, { tasks: preferences?.tasks || [], CategoryId: category._id, date: formattedDay }),
						),
				);

				setCurrentReport(report);
				navigate(`${routes.report}/${formattedDay}`);
			} else {
				console.error(`Unable to create a report for ${formattedDay}`);
			}
		} catch (err) {
			console.error(err);
		}
	}, [day, userInfos]);

	const duplicateReport = useCallback(async () => {
		try {
			if (!mostRecentUsableReportForCopy?._id) return;

			const formattedDay = dateFns.format(day, 'yyyy-MM-dd');

			const duplicatedReportResponse = await reportDuplicate(client, mostRecentUsableReportForCopy._id, day);
			const report = reportResponseToReportType(duplicatedReportResponse);

			if (!report) {
				console.error(`Unable to duplicate report`);
				return;
			}

			setCurrentReport(report);
			navigate(`${routes.report}/${formattedDay}`);
		} catch (err) {
			console.error(err);
		}
	}, [day]);

	const handleAddReport = useCallback(() => {
		setIsButtonDisabled(true);
		if (mostRecentUsableReportForCopy != null && day) {
			pushModal(
				<DuplicateReportModal
					report={mostRecentUsableReportForCopy}
					onCancel={() => setIsButtonDisabled(false)}
					onNewReport={addReport}
					onDuplicateReport={duplicateReport}
				/>,
			);
		} else {
			addReport().catch(console.error);
		}
	}, [mostRecentUsableReportForCopy, addReport]);

	const dayString = dateFns
		.format(day, 'EEEE dd', { locale: getLocaleFromCode(preferences.language) })
		.split('')
		.map((c, i) => (i === 0 ? c.toUpperCase() : c))
		.join('');

	return (
		<Container>
			<HeaderContainer isSelected={isCurrentDay}>
				{hasData ? (
					<>
						<MarginLeftAuto>
							<p>{dayString}</p>
						</MarginLeftAuto>
						<Padding>
							<Data color={isCurrentDay ? 'white' : 'black'} />
						</Padding>
					</>
				) : (
					<p>{dayString}</p>
				)}
			</HeaderContainer>
			<ContentContainer>
				{(isInPast || isCurrentDay) && isWriter && !loading && !isUserInReport && (
					<ContentPadding>
						<Button disabled={isButtonDisabled} type={ButtonType.primary} onClick={handleAddReport}>
							<Add />
						</Button>
					</ContentPadding>
				)}
				{sortDayReports(reports).map((e, i) => (
					<UserCard
						key={`CalendarDay-${i}-${e._id || '0'}`}
						username={e.editor}
						reportname={e.name}
						createdBy={e.createdBy}
						status={numIntoUserStatus(e.status || 0)}
						onClick={() => {
							const report = reportResponseToReportType(e);

							if (!report || (report.status === ReportStatus.Invalidated && report.createdBy !== username)) {
								return;
							}

							setCurrentReport(report);
							navigate(`${routes.report}/${dateFns.format(day, 'yyyy-MM-dd')}`);
						}}
					/>
				))}
			</ContentContainer>
		</Container>
	);
};

export default CalendarDay;
