import React from 'react';

export type Props = {
	color?: string;
};

const Fog: React.FC<Props> = ({ color }) => {
	return (
		<svg id="FogSVG" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M7.4027 10.8661C7.26504 10.6293 6.96218 10.5485 6.72357 10.6844C6.61344 10.7468 6.52717 10.8532 6.4923 10.9854L5.50297 14.6784C5.43138 14.9427 5.59107 15.2162 5.85355 15.2878C5.9857 15.3227 6.11969 15.3006 6.22982 15.2382C6.33995 15.1722 6.42622 15.0712 6.46109 14.9372L7.45043 11.2442C7.4853 11.112 7.46327 10.9781 7.40087 10.8679L7.4027 10.8661Z" fill={color ?? "#25465F"} />
			<path fillRule="evenodd" clipRule="evenodd" d="M4.1136 10.8661C3.97593 10.6293 3.67491 10.5485 3.4363 10.6844C3.32617 10.7486 3.2399 10.8532 3.20503 10.9854L2.2157 14.6784C2.14411 14.9427 2.3038 15.2162 2.56627 15.2878C2.69843 15.3227 2.83242 15.3006 2.94255 15.2382C3.05452 15.174 3.13895 15.0712 3.17382 14.9372L4.16315 11.2442C4.19803 11.112 4.176 10.9781 4.1136 10.8679V10.8661Z" fill={color ?? "#25465F"} />
			<path fillRule="evenodd" clipRule="evenodd" d="M10.5524 10.8662C10.4148 10.6276 10.1119 10.5468 9.87514 10.6845C9.76501 10.7469 9.67874 10.8533 9.64387 10.9855L8.65454 14.6785C8.58295 14.9446 8.74448 15.22 9.00512 15.2879C9.13727 15.3227 9.27127 15.3007 9.3814 15.2383C9.48969 15.1759 9.57963 15.0694 9.61267 14.9373L10.602 11.2443C10.6369 11.1121 10.6167 10.9781 10.5524 10.868V10.8662Z" fill={color ?? "#25465F"} />
			<path fillRule="evenodd" clipRule="evenodd" d="M14.0508 10.8662C13.9132 10.6276 13.6103 10.5468 13.3735 10.6845C13.2634 10.7469 13.1771 10.8533 13.1422 10.9855L12.1529 14.6785C12.0813 14.9446 12.2429 15.22 12.5035 15.2879C12.6356 15.3227 12.7696 15.3007 12.8798 15.2383C12.9881 15.1759 13.078 15.0694 13.111 14.9373L14.1004 11.2443C14.1352 11.1121 14.1151 10.9781 14.0508 10.868V10.8662Z" fill={color ?? "#25465F"} />
			<path fillRule="evenodd" clipRule="evenodd" d="M16.2426 12.4648C16.2426 12.5529 16.2187 12.6392 16.1765 12.7126C16.1122 12.8246 16.0094 12.909 15.8755 12.9439L12.1824 13.9351C12.1329 13.9479 12.0815 13.9534 12.0319 13.9516C11.9824 13.9553 11.9328 13.9498 11.8814 13.9351L8.46556 13.0192C8.34442 12.9861 8.21594 12.9861 8.09296 13.0192L4.67527 13.9351C4.62571 13.9479 4.57432 13.9534 4.52476 13.9516C4.4752 13.9553 4.42564 13.9498 4.37425 13.9351L0.681233 12.9439C0.547242 12.9072 0.444454 12.8246 0.380212 12.7126C0.31597 12.6025 0.293944 12.4685 0.328818 12.3364C0.400402 12.0739 0.673891 11.916 0.938202 11.9858L4.33937 12.898C4.46052 12.9311 4.589 12.9311 4.71198 12.898L8.11131 11.9858C8.16821 11.9711 8.22328 11.9656 8.27834 11.9711C8.33157 11.9656 8.39031 11.9711 8.44537 11.9858L11.8447 12.898C11.9658 12.9311 12.0962 12.9311 12.2173 12.898L15.6166 11.9858C15.881 11.916 16.1544 12.0739 16.226 12.3364C16.237 12.3786 16.2426 12.4208 16.2426 12.4648Z" fill={color ?? "#25465F"} />
			<path d="M16.2773 6.58208C16.2773 7.80636 15.2825 8.8012 14.0582 8.8012H3.03976C1.51447 8.8012 0.277344 7.56407 0.277344 6.03327C0.277344 4.50247 1.51447 3.27085 3.03976 3.27085C3.03976 1.46105 4.50632 0 6.31061 0C7.59913 0 8.71327 0.745211 9.24373 1.83182C9.6402 1.6134 10.1027 1.48492 10.5873 1.48492C12.1181 1.48492 13.3552 2.72204 13.3552 4.25284C13.3552 4.32626 13.3516 4.40335 13.3461 4.47677C13.57 4.39968 13.8068 4.35746 14.0582 4.35746C15.2825 4.35746 16.2773 5.3523 16.2773 6.58208Z" fill={color ?? "#25465F"} />
		</svg>
	);
};

export default Fog;
