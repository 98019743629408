import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";

import { RolesByWorksite, useKeycloak } from "providers/keycloak";
import Button, { Type as ButtonType } from "components/Button";
import { ReportSelector } from "components/ExportModal/styles";
import { useClient } from "providers/client";
import Cross from "assets/svg/Cross";
import routes from "utils/routes";
import i18n from "utils/lang";

import { CrossContainer } from "../ModalHeader/style";
import Container, {
    ContainerPopUp,
    TitlePopUp,
    WrapperPopUp,
    TitlePadding,
    TitleAndCrossContainer,
    FlexPopUp
} from "./style";

type Props = {
    close: () => void;
}

const SelectWorksitePopUpContent: React.FC<Props> = ({ close }) => {
    const navigate = useNavigate();
    const { worksite } = useClient();
    const { roleByWorksite } = useKeycloak();
    const filteredRoleByWorksite = roleByWorksite
        .filter((e): e is Required<RolesByWorksite> => !!e.unauthData);
    const [code, setCode] = useState<string | undefined>(
        filteredRoleByWorksite.find((e) => e.unauthData.code === worksite.code)?.unauthData.code
    );

    const goToWorksite = (): void => {
        if (code) {
            navigate(`${routes.worksite}/${code}`);
        }
    }

    return (
        <ContainerPopUp>
            <FlexPopUp>
                <TitleAndCrossContainer>
                    <TitlePopUp>{i18n.t('change_site')}</TitlePopUp>
                    <CrossContainer onClick={close}>
                        <Cross />
                    </CrossContainer>
                </TitleAndCrossContainer>
                <ReportSelector
                    name="export-report"
                    id="export-report-select"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                >
                    {filteredRoleByWorksite.map((e) => (
                        <option key={`worksite-${e.worksite}`} value={e.unauthData.code}>
                            {e.unauthData.name ?? e.unauthData.code}
                        </option>
                    ))}
                </ReportSelector>
                <div>
                    <Button type={ButtonType.modalValidator} onClick={goToWorksite} disabled={code === undefined || code === ""}>
                        {i18n.t('validate')}
                    </Button>
                </div>
            </FlexPopUp>
        </ContainerPopUp>
    )
}


const SelectWorksitePopUp: React.FC = () => {
    const { roleByWorksite } = useKeycloak();
    const { worksite } = useClient();
    const [open, setOpen] = useState(false);
    const containerRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    useOnClickOutside(containerRef, () => {
        if (open) {
            setOpen(false);
        }
    });

    return (
        <Container ref={containerRef}>
            <TitlePadding isClickable={roleByWorksite.length > 1} onClick={() => setOpen(!open)}>{worksite.name ?? worksite.code}</TitlePadding>
            {open && roleByWorksite.length > 1 && (
                <WrapperPopUp>
                    <SelectWorksitePopUpContent close={() => { setOpen(false) }} />
                </WrapperPopUp>
            )}
        </Container>
    )
}

export default SelectWorksitePopUp;