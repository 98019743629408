import React, { useCallback, useContext, useEffect, useId, useState } from 'react';

import { Flex, Margin } from 'styles';
import { showError } from 'utils/error';
import ArrowDown from 'assets/svg/ArrowDown';
import ArrowRight from 'assets/svg/ArrowRight';
import CustomTooltip from 'components/Tooltip';
import { ReportStatus } from 'api/types/report';
import Input, { Style } from 'components/Input';
import Button, { Type } from 'components/Button';
import { CategoryType } from 'api/types/category';
import { FeaturesFlag, hasFlags } from 'api/utils';
import { updateCounter } from 'api/routes/counter';
import { useReports } from 'providers/ReportsProvider';
import { PRODUCTION_TYPE_REFERENCE } from 'utils/constants';
import { updateCheckedCounter } from 'api/routes/checkedCounter';
import { AccordionState, useAccordion } from 'widgets/Report/provider/accordion';
import { CounterByTask, CounterType, CounterTypeFromTask } from 'api/types/counter';
import { ReportDataTasksContext, useReportDataTasks } from 'widgets/Report/provider/counters/tasks';
import { useClient } from 'providers/client';
import { getFilterColor } from '../../provider/counters/filter';
import { useCategory } from '../../provider/category';
import Container, {
	AccordionContainer,
	AccordionRowText,
	BorderBottom,
	CursorPointerFlex,
	InputSize,
	LeftContainer,
	RestrainOverflow,
	RowCard,
	RowInput,
	RowText,
	SectionContainer,
	TextSize,
} from './style';
import { CommentTaskWidget } from 'widgets/ReportHeader/components/Comment';

type ContentProps = {
	unit: string;
	counter: CounterTypeFromTask;
	inputString: string;
};

// Building ASTs for regex is a costly operation.
// Regex are defined here to only do that operation once.
const nonNumberCharsRegex = /[^0-9.]/g;
const extraEndingDotsRegex = /\.\.+$/;
const validNumberRegex = /^\d+(\.\d*)?$/;

const Content: React.FC<ContentProps> = ({ unit, counter, inputString }) => {
	const { clientHandler: { client } } = useClient();
	const { fetchCounters } = useContext(ReportDataTasksContext);
	const [inputValue, setInputValue] = useState<string>(inputString);
	const { canEdit, currentReport } = useReports();

	const updateCounterFn = currentReport?.status === ReportStatus.Validated ? updateCheckedCounter : updateCounter;

	const updateOrCreateQuantity = useCallback(async (): Promise<CounterType | void> => {
		if (!validNumberRegex.test(inputValue)) return;

		const value = parseFloat(inputValue);

		return updateCounterFn(client, { quantity: value }, counter._id.toString()).catch(
			showError('Failed to update quantity inside counter'),
		);
	}, [inputValue, counter]);

	return (
		<>
			<RowInput size={InputSize.Medium}>
				<Input
					value={inputValue}
					style={Style.Content}
					onChange={(evt) => {
						if (!canEdit) return;
						if (evt.target.value === "") {
							setInputValue("0")
							return;
						}
						let cleanValue = evt.target.value.replace(nonNumberCharsRegex, '');
						if (cleanValue === '.') cleanValue = '0';
						cleanValue = cleanValue.replace(extraEndingDotsRegex, '.');

						if (validNumberRegex.test(cleanValue)) {
							setInputValue(cleanValue);
						}
					}}
					onBlur={() => {
						(async () => {
							// Ensure no extra dot stays in the input field if the input value ends with a dot
							if (inputValue.endsWith('.')) {
								setInputValue(inputValue.slice(0, -1));
							}

							await updateOrCreateQuantity();
							fetchCounters();
						})().catch(showError(`Failed to create or update quantity`));
					}}
					onFocus={(evt) => {
						evt.target.select();
					}}
					textAlign="end"
				/>
			</RowInput>
			<Margin left={5}>
				<RowText size={TextSize.Small}>{unit}</RowText>
			</Margin>
		</>
	);
};

type RowProps = {
	counter: CounterTypeFromTask;
	category: CategoryType;
	sourceID: string;
	unit: string;
	isEOTPView?: boolean;
};

const RowTask: React.FC<RowProps> = ({ counter, category, unit, isEOTPView }) => {
	const categories = useCategory();
	const filterColor = getFilterColor(counter.comeFrom);
	const { sources } = useReportDataTasks();
	const source = sources.find((s) => s._id === counter.SourceId);
	const categoryOfSource = categories.find((c) => c._id === source?.CategoryId);
	const lineTitleID = useId();

	const title =
		category.typeReference === PRODUCTION_TYPE_REFERENCE && counter.name
			? counter.name.split('|').at(0)
			: !isEOTPView
				? counter.name
				: hasFlags(categoryOfSource?.features ?? 0, FeaturesFlag.LabelBefore)
					? [counter.name, counter.code].filter(Boolean).join(' - ')
					: [counter.code, counter.name].filter(Boolean).join(' - ');

	return (
		<Flex>
			<LeftContainer>
				<Button type={Type.colorized} color={filterColor} />
				<RowText size={TextSize.Large}>{title}</RowText>
				<Flex width="160px" data-tooltip-id={lineTitleID} >
					<RowCard>
						<RestrainOverflow>
							{!!source ? source.label : counter.work}
						</RestrainOverflow>
					</RowCard>
					<CustomTooltip
						id={lineTitleID}
						place='top-start'
					>
						{!!source ? source.label : counter.work}
					</CustomTooltip>
				</Flex>
				<RowText size={TextSize.Unset}>
					<Flex flexDirection="row" alignItems="center" margin="4px 10px">
						<Content unit={unit} counter={counter} inputString={counter.quantity.toString()} />
					</Flex>
				</RowText>
			</LeftContainer>
		</Flex>
	);
};

type Props = {
	task: CounterByTask;
	category: CategoryType;
};

const AccordionTask: React.FC<Props> = ({ task, category }) => {
	const { accordionState } = useAccordion();
	const [open, setOpen] = useState<boolean>(accordionState === AccordionState.AllOpen);
	const taskTitleID = useId();

	const isEOTPView = category.name === 'EOTP';

	useEffect(() => {
		if (accordionState === AccordionState.AllOpen) {
			setOpen(true);
		} else if (accordionState === AccordionState.AllClosed) {
			setOpen(false);
		}
	}, [accordionState])

	return (
		<SectionContainer>
			<AccordionContainer isTaskView>
				<Flex
					alignItems="center"
					width="95%"
					backgroundColor="var(--title-column-bg-color, unset)"
					borderRadius="8px"
					minWidth="0"
					margin="0 0 0 10px"
				>
					<LeftContainer onClick={() => setOpen(!open)} isCursorPointer>
						<AccordionRowText data-tooltip-id={taskTitleID} isEOTP>
							<b>{task?.task}</b>
						</AccordionRowText>
						<CustomTooltip
							id={taskTitleID}
							place='top-start'
						>
							{task?.task}
						</CustomTooltip>
					</LeftContainer>
				</Flex>
				<CursorPointerFlex alignItems="center" justifyContent='center' onClick={() => setOpen(!open)}>
					{open ? <ArrowDown color="black" /> : <ArrowRight color="black" />}
				</CursorPointerFlex>
			</AccordionContainer>
			{open && (
				<Flex flexDirection='row'>
					{task.sources.length > 0 && (
						<Flex flexDirection='column' flexGrow={1} >
							{task.sources.map((source, i) => {
								return (
									<BorderBottom key={`Accordion-BorderBottom-${i}-${source._id}`}>
										{source.counters &&
											source.counters
												.filter((counter) => !isEOTPView || counter.include === true)
												.map((counter, idx) => (
													<Container key={`Accordion-Container-${idx}-${counter.code}`}>
														<RowTask
															category={category}
															counter={counter}
															unit={source.unit}
															sourceID={source._id.toString()}
															isEOTPView={isEOTPView}
														/>
													</Container>
												))}

									</BorderBottom>
								);
							})}
						</Flex>
					)}
					{!isEOTPView && task.task !== "Undefined" && (
						<Flex flexGrow={1} width={task.sources.length > 0 ? "80%" : "100%"}>
							<CommentTaskWidget task={task} />
						</Flex>
					)}
				</Flex>
			)}
		</SectionContainer>
	);
};

export default AccordionTask;
