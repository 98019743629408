import React from "react";
import { Tooltip, type ITooltip } from "react-tooltip";


export type Props = ITooltip & {
    children: React.ReactNode;
    backgroundColor?: string;
    color?: string;
    width?: string;
}

const CustomTooltip: React.FC<Props> = ({ children, backgroundColor, color, width, ...props }) => {
    return (
        <Tooltip
            {...props}
            style={{
                backgroundColor: backgroundColor ?? "#25465F",
                borderRadius: 8,
                fontWeight: 700,
                color: color ?? '#FFF',
                padding: '8px 6px',
                width: width ?? 'auto',
                zIndex: 999
            }}
        >
            {children}
        </Tooltip>
    )
}

export default CustomTooltip;