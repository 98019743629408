import React, { createContext, useContext, useEffect, useState } from 'react';

import { updateUser } from 'api/routes/user';
import { ReferentialResponse } from 'api/types/ref';
import { useLocalStorageState } from 'utils/common';
import { showError } from 'utils/error';
import i18n, { Languages, getCurrentLang, getLanguageFromCode } from 'utils/lang';

import { useUser } from './user';
import { useClient } from './client';
import { useKeycloak } from './keycloak';

export type TaskPreferences = {
	categoryID: number;
	refs: ReferentialResponse[];
};

export enum ThemeSelection {
	Dark = 1,
	Light = 2,
}

export type TimePref = {
	hours: number;
	minutes: number;
};

export type PreferencesType = {
	clientId?: string;
	tasks?: TaskPreferences[];
	theme?: ThemeSelection;
	startTime?: number;
	endTime?: number;
	language?: Languages;
};

export type PrefContextType = {
	preferences: PreferencesType;
	viewRessource: boolean;
	setTaskPreferences: (value: TaskPreferences[]) => void;
	setTheme: React.Dispatch<React.SetStateAction<ThemeSelection | undefined>>;
	setStartTime: React.Dispatch<React.SetStateAction<number | undefined>>;
	setEndTime: React.Dispatch<React.SetStateAction<number | undefined>>;
	setLanguage: (value: Languages) => void;
	setViewRessource: React.Dispatch<React.SetStateAction<boolean>>;
};

const PrefContext = createContext<PrefContextType>({
	preferences: {
		tasks: undefined,
		theme: undefined,
		startTime: undefined,
		endTime: undefined,
		language: undefined
	},
	viewRessource: false,
	setTaskPreferences: () => { },
	setTheme: () => { },
	setStartTime: () => { },
	setEndTime: () => { },
	setLanguage: () => { },
	setViewRessource: () => { }
});

type Props = {
	children: React.ReactNode;
	changeLng: React.Dispatch<Date | undefined>;
};

export const PrefProvider: React.FC<Props> = ({ children, changeLng }) => {
	const { keycloak } = useKeycloak();
	const { clientHandler: { client } } = useClient();
	const user = useUser();
	const [theme, setTheme] = useLocalStorageState<ThemeSelection | undefined>('user-theme-preferences', undefined, keycloak);
	const [startTime, setStartTime] = useLocalStorageState<number | undefined>('user-start-time-preferences', undefined, keycloak);
	const [endTime, setEndTime] = useLocalStorageState<number | undefined>('user-end-time-preferences', undefined, keycloak);
	const [viewRessource, setViewRessource] = useLocalStorageState<boolean>('user-view-ressource-preferences', false, keycloak);
	const [language, setLanguage] = useState<Languages>();
	const [taskPreferences, setTaskPreferences] = useState<TaskPreferences[]>();

	const setDefaultValues = async (): Promise<void> => {
		setTaskPreferences([]);
		setTheme(ThemeSelection.Light);
		setStartTime(0);
		setEndTime(0);
		const navigatorLang = getLanguageFromCode(getCurrentLang);
		await i18n.changeLanguage(navigatorLang)
		changeLng(new Date(Date.now()));
		setLanguage(navigatorLang);
	}

	const getOrMigrate = async (settings: string, updateIfNotArray: boolean): Promise<PreferencesType[]> => {
		if (!keycloak) throw new Error("User should be connected")
		const prefs: PreferencesType[] = JSON.parse(settings) as PreferencesType[];

		if (!Array.isArray(prefs)) {
			const newPref: PreferencesType[] = [{
				clientId: keycloak.clientId,
				tasks: taskPreferences,
				theme,
				startTime,
				endTime,
				language
			}];

			if (updateIfNotArray) {
				await updateUser(client, keycloak, {
					settings: JSON.stringify(newPref),
				});
			}

			return newPref;
		}
		return prefs
	}

	const updateSettings = async (newPref: PreferencesType): Promise<void> => {
		if (!keycloak) throw new Error("User should be connected")
		const prefs = user?.settings ? await getOrMigrate(user.settings, false) : [];
		const prefIndex = prefs.findIndex((e) => e.clientId && e.clientId === keycloak.clientId);

		if (prefIndex !== -1) {
			prefs[prefIndex] = newPref;
		} else {
			prefs.push(newPref);
		}
		await updateUser(client, keycloak, { settings: JSON.stringify(prefs) });
	}

	useEffect(() => {
		if (!keycloak) return;
		void (async () => {
			try {
				if (user?.settings) {
					const prefs = await getOrMigrate(user.settings, true);
					const pref = prefs.find((e: Partial<PreferencesType>) => e.clientId && e.clientId === keycloak.clientId);
					if (pref) {
						if (pref.tasks) setTaskPreferences(pref.tasks);
						if (pref.theme) setTheme(pref.theme);
						if (pref.startTime) setStartTime(pref.startTime);
						if (pref.endTime) setEndTime(pref.endTime);
						if (pref.language) {
							await i18n.changeLanguage(pref.language);
							changeLng(new Date(Date.now()));
							setLanguage(pref.language);
						}
						return;
					}
				}
				// Setup default values in localstorage when no data is stored for the user
				// Will trigger the useEffect below, and store preferences in the bdd for the current user 
				await setDefaultValues();

			} catch (err) {
				showError('Failed to load user preferences')(new Error(JSON.stringify(err)));
			}
		})();
	}, []);

	const setTaskPref = (newPrefs: TaskPreferences[]): void => {
		if (!keycloak) return;
		const newPref: PreferencesType = {
			clientId: keycloak.clientId,
			tasks: newPrefs,
			theme,
			startTime,
			endTime,
			language
		};
		updateSettings(newPref)
			.then(() => { setTaskPreferences(newPrefs); })
			.catch(showError('Failed to update user\'s settings'));

	}

	const setLang = (lang: Languages): void => {
		if (!keycloak) return;
		const newPref: PreferencesType = {
			clientId: keycloak.clientId,
			tasks: taskPreferences,
			theme,
			startTime,
			endTime,
			language: lang
		};

		updateSettings(newPref)
			.then(async () => {
				setLanguage(lang);
				await i18n.changeLanguage(lang);
				changeLng(new Date(Date.now()));
			})
			.catch(showError('Failed to update user\'s settings'));

	}

	return (
		<PrefContext.Provider
			value={{
				preferences: {
					tasks: taskPreferences,
					theme,
					startTime,
					endTime,
					language
				},
				viewRessource,
				setTaskPreferences: setTaskPref,
				setTheme,
				setStartTime,
				setEndTime,
				setLanguage: setLang,
				setViewRessource,
			}}
		>
			{children}
		</PrefContext.Provider>
	);
};

export const usePreferences = (): PrefContextType => useContext(PrefContext);
