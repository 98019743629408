import styled from 'styled-components';

import { SearchType } from '.';

type Props = {
	type?: SearchType;
};

const Container = styled.div<Props>`
	display: flex;
	justify-content: space-between;
	align-items: center;

	background: #ffffff;
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	padding: ${({ type }) => type && type === SearchType.Report ? '8px 14px' : '12px 18px'};

	${({ type }) =>
		type &&
		type === SearchType.FullScreen &&
		`
		width: 98%;
	`}

	${({ type }) =>
		type &&
		type === SearchType.FullWidth &&
		`
		width: 100%;
	`}

	${({ type }) =>
		type &&
		type === SearchType.ResourceModal &&
		`
		width: 30%;
		height: 25px;
		padding: 8px 8px 8px 12px;
	`}

	${({ type }) =>
		type &&
		type === SearchType.AddFreeResource &&
		`
		min-width: 50%;
		flex-grow: 1;
		height: 25px;
		padding: 8px 8px 8px 12px;
	`}

	${({ type }) =>
		type &&
		type === SearchType.FilterCounter &&
		`
		min-width: 120px;
		max-width: 150px;
		height: 25px;
		padding: 8px 8px 8px 12px;
		align-self: center;
	`}
`;

export const IconContainer = styled.div<Props>`
	display: ${({ type }) => type && type === SearchType.Report ? 'none' : 'block'};
	margin-left: 5px;
`;

export const CursorPointer = styled.div`
	cursor: pointer;
`;

export default Container;
