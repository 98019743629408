import React from 'react';

export type Props = {
	color?: string;
};

const Frost: React.FC<Props> = ({ color }) => {
	return (
		<svg id="FrostSVG" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M16.0028 11.1972L14.3953 11.6179L12.697 10.6324L14.5059 10.1565L14.2512 9.17107L11.4568 9.91953L9.30228 8.67737L11.4568 7.4352L14.2512 8.18366L14.5059 7.19823L12.697 6.72229L14.3953 5.73686L16.0028 6.1575L16.2773 5.17206L14.9621 4.82449L15.3097 3.50926L14.3242 3.23476L13.8858 4.84226L12.1875 5.8277L12.6812 4.01876L11.676 3.76401L10.9453 6.55838L8.7908 7.78277V5.31622L10.8367 3.27031L10.1238 2.53962L8.7908 3.85485V1.89978L9.95989 0.730684L9.24698 0L8.27932 0.967663L7.30771 0L6.5948 0.730684L7.76389 1.89978V3.85485L6.43089 2.53962L5.71798 3.27031L7.76389 5.31622V7.78277L5.60936 6.55838L4.87868 3.76401L3.87349 4.01876L4.3672 5.8277L2.66885 4.84226L2.23044 3.23476L1.24501 3.50926L1.59257 4.82449L0.277344 5.17206L0.551844 6.1575L2.15935 5.73686L3.85769 6.72229L2.04876 7.19823L2.30351 8.18366L5.09788 7.4352L7.25241 8.67737L5.09788 9.91953L2.30351 9.17107L2.04876 10.1565L3.85769 10.6324L2.15935 11.6179L0.551844 11.1972L0.277344 12.1827L1.59257 12.5302L1.24501 13.8455L2.23044 14.12L2.66885 12.5125L4.3672 11.527L3.87349 13.336L4.87868 13.5907L5.60936 10.7964L7.76389 9.57196V12.0385L5.71798 14.0844L6.43089 14.8151L7.76389 13.4999V15.455L6.5948 16.6241L7.30771 17.3547L8.27537 16.3871L9.24303 17.3547L9.95594 16.6241L8.78685 15.455V13.4999L10.1199 14.8151L10.8328 14.0844L8.78685 12.0385V9.57196L10.9414 10.7964L11.6721 13.5907L12.6772 13.336L12.1835 11.527L13.8819 12.5125L14.3203 14.12L15.3057 13.8455L14.9582 12.5302L16.2734 12.1827L15.9989 11.1972H16.0028Z" fill={color ?? "#25465F"} />
		</svg>
	);
};

export default Frost;
