import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import Keycloak from 'keycloak-js';

import { showError } from 'utils/error';
import Loader, { LoadingType } from 'components/Loader';
import { getCurrentWorksite, WorksiteResponse } from 'api/routes/worksite';
import { ClientHandler, clientHandler } from "api/client";
import { getPendingRequests, setPendingRequests } from "utils/pendingRequests";

import { useKeycloak } from './keycloak';

type ClientProviderType = {
    clientHandler: ClientHandler;
    worksite: WorksiteResponse;
};

const ClientContext = createContext<ClientProviderType>({
    clientHandler: {} as ClientHandler,
    worksite: {} as WorksiteResponse,
});

export const ClientProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { clientId, keycloak } = useKeycloak();
    const [worksite, setWorksite] = useState<WorksiteResponse>();

    const interceptorDecreasePendingReq = (e: AxiosResponse): AxiosResponse => {
        const pendingRequests = getPendingRequests();
        setPendingRequests(pendingRequests - 1 >= 0 ? pendingRequests - 1 : 0);
        return e;
    }

    const interceptorAuthorization = (kc: Keycloak) => (request: InternalAxiosRequestConfig<unknown>): InternalAxiosRequestConfig<unknown> => {
        const pendingRequests = getPendingRequests();
        const token = kc.token;
        if (token) {
            request.headers.Authorization = `Bearer ${token}`;
        } else {
            console.error('There is no token');
        }

        setPendingRequests(pendingRequests + 1);

        return request;
    }

    const interceptorKeycloakRefreshOnError = (kc: Keycloak) => async (error: AxiosError): Promise<AxiosError> => {
        const pendingRequests = getPendingRequests();
        setPendingRequests(pendingRequests - 1 >= 0 ? pendingRequests - 1 : 0);
        if (axios.isAxiosError(error) && error.config) {
            if (error.response && error.response.status === 401) {
                // We ask for a token validity of 30 seconds.
                // Note: We don't have retry on fail system
                const refreshed = await kc.updateToken(30);

                if (refreshed) {
                    error.config.headers.Authorization = `Bearer ${kc.token ?? ''}`;
                }
                return axios(error.config);
            }
        }
        return error;
    }

    useEffect(() => {
        if (!keycloak) return;

        for (const clientKey in clientHandler) {
            const key = clientKey as keyof ClientHandler;

            if (clientHandler.hasOwnProperty(key)) {
                clientHandler[key].interceptors.response.use(interceptorDecreasePendingReq, interceptorKeycloakRefreshOnError(keycloak));
                clientHandler[key].interceptors.request.use(interceptorAuthorization(keycloak));
            }
        }

        (async () => {
            const currentWorksite = await getCurrentWorksite(clientHandler.client);

            clientHandler.exportClient.defaults.baseURL = currentWorksite.export_url;
            clientHandler.referentialClient.defaults.baseURL = currentWorksite.referential_url;
            setWorksite(currentWorksite);
        })().catch(showError('Failed to fetch current Worksite'));
    }, [keycloak, clientId]);

    if (!keycloak || !worksite) return <Loader type={LoadingType.Fullscreen} />

    return (
        <ClientContext.Provider value={{ clientHandler, worksite }}>
            {children}
        </ClientContext.Provider>
    );
};

export const useClient: () => ClientProviderType = () => useContext(ClientContext);
