import styled from 'styled-components';
import { Flex } from 'styles';

export const ModalBody = styled.form`
	width: 100%;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: start;
`;

export const ReportSelector = styled.select`
	width: 100%;
	border-radius: 5px;
	border: none;
	background-color: white;
	padding: 0.75em 0.5em;
	cursor: pointer;

	appearance: none;
	background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2213%22%20height%3D%228%22%20fill%3D%22none%22%3E%3Cpath%20fill%3D%22%23000%22%20d%3D%22m6.5%207.713%206.01-6.01L11.097.288%206.5%204.888%201.904.288.49%201.702%206.5%207.713Z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat;
	background-position: right 1em center;
	background-size: 1em;

	font-weight: bold;

	&:focus {
		outline: none;
	}
`;

export const DateSelection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;

	& > * {
		margin-right: 0.5em;
	}
`;

export const DateInput = styled.input`
	width: 100%;
	border-radius: 5px;
	border: none;
	background-color: white;
	padding: 0.75em 0.5em;

	&::-webkit-calendar-picker-indicator {
		appearance: none;
		background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2219%22%20height%3D%2221%22%20fill%3D%22none%22%3E%3Cpath%20fill%3D%22%23000%22%20d%3D%22M16.209%2020.083H2.792a1.917%201.917%200%200%201-1.917-1.916V4.75c0-1.059.858-1.917%201.917-1.917h1.916V.917h1.917v1.916h5.75V.917h1.917v1.916h1.917c1.058%200%201.916.858%201.916%201.917v13.417a1.917%201.917%200%200%201-1.916%201.916ZM2.792%208.583v9.584h13.417V8.583H2.792Zm0-3.833v1.917h13.417V4.75H2.792Zm11.5%2011.5h-1.917v-1.917h1.917v1.917Zm-3.834%200H8.542v-1.917h1.916v1.917Zm-3.833%200H4.708v-1.917h1.917v1.917Zm7.667-3.833h-1.917V10.5h1.917v1.917Zm-3.834%200H8.542V10.5h1.916v1.917Zm-3.833%200H4.708V10.5h1.917v1.917Z%22%2F%3E%3C%2Fsvg%3E');
		background-repeat: no-repeat;
		background-position: right 0 center;
		background-size: 1em;

		cursor: pointer;
	}
`;

export const ValidateButton = styled.button`
	align-self: center;

	color: white;
	background-color: hsl(198, 47%, 53%);
	font-weight: 700;

	padding: 0.75em 3.5em;

	border-radius: 5px;

	display: grid;
	place-content: center;

	&[disabled] {
		background-color: hsl(198, 47%, 70%);

		&[data-loading='true'] {
			cursor: progress;
		}
		&[data-loading='false'] {
			cursor: not-allowed;
		}
	}
`;

export const CircularProgress = styled.div`
	@keyframes rotation {
		from {
			transform: rotate(0);
		}

		to {
			transform: rotate(359deg);
		}
	}

	width: 1.5em;
	height: 1.5em;
	border-radius: 50%;
	background: radial-gradient(closest-side, hsl(198, 47%, 70%) 79%, transparent 80% 100%),
		conic-gradient(white 75%, transparent 0);

	animation: rotation 0.75s infinite linear;

	& > progress {
		visibility: hidden;
		height: 0;
		width: 0;
	}
`;

export const UserListContainer = styled(Flex)`
	max-height: 200px;
	overflow-y: auto;
	overflow-x: hidden;
	flex-direction: column;
	gap: 5px;
	background-color: white;
	border-radius: 10px;
	width: 95%;
	height: auto;
	padding: 20px;
`;

export const Separator = styled.hr`
	border-color: #000;
	width: 100%;
`