import React from 'react';

export type Props = {
	color?: string;
};

const Wind: React.FC<Props> = ({ color }) => {
	return (
		<svg id="WindSVG" width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M9.44774 5.61151H3.13964C2.94331 5.61151 2.7842 5.4524 2.7842 5.25607C2.7842 5.05974 2.94331 4.90063 3.13964 4.90063H9.44774C10.4204 4.90063 11.2108 4.11019 11.2108 3.13754C11.2108 2.16489 10.4204 1.37446 9.44774 1.37446C8.6804 1.37446 8.00673 1.86463 7.76934 2.59347C7.70903 2.78082 7.50758 2.88219 7.32151 2.82188C7.13417 2.76157 7.0328 2.5614 7.09311 2.37405C7.42417 1.35136 8.37115 0.663574 9.44646 0.663574C10.8105 0.663574 11.9204 1.77352 11.9204 3.13754C11.9204 4.50156 10.8105 5.61151 9.44646 5.61151H9.44774Z" fill={color ?? "#25465F"} />
			<path d="M15.8047 7.81726H0.631075C0.434749 7.81726 0.275635 7.65815 0.275635 7.46182C0.275635 7.2655 0.434749 7.10638 0.631075 7.10638H15.8047C16.7773 7.10638 17.5677 6.31595 17.5677 5.3433C17.5677 4.37065 16.7773 3.58021 15.8047 3.58021C15.0373 3.58021 14.3636 4.07038 14.1263 4.79923C14.066 4.98529 13.8645 5.08794 13.6784 5.02763C13.4911 4.96732 13.3897 4.76715 13.45 4.5798C13.7811 3.55711 14.7281 2.86933 15.8034 2.86933C17.1674 2.86933 18.2773 3.97928 18.2773 5.3433C18.2773 6.70731 17.1674 7.81726 15.8034 7.81726H15.8047Z" fill={color ?? "#25465F"} />
			<path d="M12.793 14.2472C11.7164 14.2472 10.7707 13.5594 10.4396 12.5367C10.3793 12.3494 10.482 12.1492 10.668 12.0889C10.8554 12.0286 11.0556 12.1312 11.1159 12.3173C11.352 13.0462 12.0269 13.5363 12.7943 13.5363C13.7669 13.5363 14.5574 12.7459 14.5574 11.7732C14.5574 10.8006 13.7669 10.0102 12.7943 10.0102H3.13964C2.94331 10.0102 2.7842 9.85104 2.7842 9.65471C2.7842 9.45839 2.94331 9.29927 3.13964 9.29927H12.793C14.157 9.29927 15.267 10.4092 15.267 11.7732C15.267 13.1373 14.157 14.2472 12.793 14.2472Z" fill={color ?? "#25465F"} />
			<path d="M9.44774 5.61151H3.13964C2.94331 5.61151 2.7842 5.4524 2.7842 5.25607C2.7842 5.05974 2.94331 4.90063 3.13964 4.90063H9.44774C10.4204 4.90063 11.2108 4.11019 11.2108 3.13754C11.2108 2.16489 10.4204 1.37446 9.44774 1.37446C8.6804 1.37446 8.00673 1.86463 7.76934 2.59347C7.70903 2.78082 7.50758 2.88219 7.32151 2.82188C7.13417 2.76157 7.0328 2.5614 7.09311 2.37405C7.42417 1.35136 8.37115 0.663574 9.44646 0.663574C10.8105 0.663574 11.9204 1.77352 11.9204 3.13754C11.9204 4.50156 10.8105 5.61151 9.44646 5.61151H9.44774Z" stroke={color ?? "#25465F"} strokeWidth="0.5" />
			<path d="M15.8047 7.81726H0.631075C0.434749 7.81726 0.275635 7.65815 0.275635 7.46182C0.275635 7.2655 0.434749 7.10638 0.631075 7.10638H15.8047C16.7773 7.10638 17.5677 6.31595 17.5677 5.3433C17.5677 4.37065 16.7773 3.58021 15.8047 3.58021C15.0373 3.58021 14.3636 4.07038 14.1263 4.79923C14.066 4.98529 13.8645 5.08794 13.6784 5.02763C13.4911 4.96732 13.3897 4.76715 13.45 4.5798C13.7811 3.55711 14.7281 2.86933 15.8034 2.86933C17.1674 2.86933 18.2773 3.97928 18.2773 5.3433C18.2773 6.70731 17.1674 7.81726 15.8034 7.81726H15.8047Z" stroke={color ?? "#25465F"} strokeWidth="0.5" />
			<path d="M12.793 14.2472C11.7164 14.2472 10.7707 13.5594 10.4396 12.5367C10.3793 12.3494 10.482 12.1492 10.668 12.0889C10.8554 12.0286 11.0556 12.1312 11.1159 12.3173C11.352 13.0462 12.0269 13.5363 12.7943 13.5363C13.7669 13.5363 14.5574 12.7459 14.5574 11.7732C14.5574 10.8006 13.7669 10.0102 12.7943 10.0102H3.13964C2.94331 10.0102 2.7842 9.85104 2.7842 9.65471C2.7842 9.45839 2.94331 9.29927 3.13964 9.29927H12.793C14.157 9.29927 15.267 10.4092 15.267 11.7732C15.267 13.1373 14.157 14.2472 12.793 14.2472Z" stroke={color ?? "#25465F"} strokeWidth="0.5" />
		</svg>
	);
};

export default Wind;
