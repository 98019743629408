import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { AxiosInstance } from 'axios';
import Keycloak from 'keycloak-js';

import { createMe, getMe } from 'api/routes/user';
import { showError } from 'utils/error';
import { UserKeycloakInfo } from 'utils/type';
import Loader, { LoadingType } from 'components/Loader';

import { useClient } from './client';
import { useKeycloak } from './keycloak';

export enum UserRoles {
	Reader = 'Reader',
	Writer = 'Writer',
	Checker = 'Checker',
	Locker = 'Locker',
	Administrator = 'Administrator',
}

export type UserContextType = {
	infos: UserKeycloakInfo;
	settings: string;
	logout: () => void;
	isReader: boolean;
	isWriter: boolean;
	isChecker: boolean;
	isLocker: boolean;
	isAdministrator: boolean;
	roles: UserRoles[];
	loaded: boolean;
};

type JwtPayload = {
	resource_access: {
		[key: string]: {
			roles: string[];
		};
	};
};

const getOrCreateUser = async (client: AxiosInstance, keycloak: Keycloak): Promise<UserResponse | undefined> => {
	try {
		const res = await getMe(client, keycloak);
		if (!res) throw Error();
		return res;
	} catch (err) {
		return createMe(client, keycloak);
	}
};

const UserContext = createContext<UserContextType | null>(null);

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const { setClientId, setKeycloak, keycloak } = useKeycloak();
	const { clientHandler: { client } } = useClient();
	const [infos, setInfos] = useState<UserKeycloakInfo | undefined>(keycloak?.userInfo as UserKeycloakInfo | undefined);
	const [settings, setSettings] = useState<string>();
	const userLoaded = useRef<boolean>(false);
	const userRoles = useMemo<UserRoles[]>(() => (infos?.roles ?? []) as UserRoles[], [infos]);

	const [isReader, isWriter, isChecker, isLocker, isAdministrator] = useMemo(() => {
		return [
			// We consider that a user is a reader if it specifically has the role or if it has no specific role at all
			userRoles.includes(UserRoles.Reader) || userRoles.length === 0,
			userRoles.includes(UserRoles.Writer),
			userRoles.includes(UserRoles.Checker),
			userRoles.includes(UserRoles.Locker),
			userRoles.includes(UserRoles.Administrator),
		];
	}, [infos]);

	useEffect(() => {
		if (!keycloak || !keycloak.subject) return;
		getOrCreateUser(client, keycloak).then((me) => {
			setSettings(me?.settings ?? '');
		}).catch(console.error)
	}, [keycloak?.subject])

	useEffect(() => {
		if (keycloak && !infos) {
			keycloak
				.loadUserInfo()
				.then((userInfos) => {
					const decodedToken = jwtDecode<JwtPayload>(keycloak?.token ?? '');
					const roles = decodedToken.resource_access[keycloak.clientId ?? '']?.roles ?? [];
					userLoaded.current = true;

					setInfos({ ...userInfos, roles } as UserKeycloakInfo);
				})
				.catch(showError('Failed to load keycloak userInfos'));
		}
	}, [keycloak]);

	const logout = (): void => {
		setClientId(undefined);
		setKeycloak(undefined);
		keycloak?.logout({ redirectUri: `${window.location.origin}/` }).catch(showError(`Unable to log out`));
	};

	if (!infos || !settings) return <Loader type={LoadingType.Fullscreen} />

	return (
		<UserContext.Provider
			value={{
				infos,
				settings,
				logout,
				isReader,
				isWriter,
				isChecker,
				isLocker,
				isAdministrator,
				roles: userRoles,
				loaded: userLoaded.current,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export const useUser = (): UserContextType | null => useContext(UserContext);

export function useRBAC({ anyRoleIn, redirectTo }: { anyRoleIn: UserRoles[]; redirectTo: string }): void {
	const navigate = useNavigate();
	const user = useUser();

	useEffect(() => {
		if (anyRoleIn.length === 0 || !user?.loaded) return;

		if (redirectTo === '') {
			console.warn(`A list of roles was passed to useRBAC without a redirect route`);
		}

		if (!anyRoleIn.some((role) => user?.roles.includes(role))) {
			navigate(redirectTo);
		}
	}, [user]);
}
