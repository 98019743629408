import React from 'react';

type Props = {
	color?: string;
	size?: number | string;
}

const Lock: React.FC<Props> = ({ color, size }) => {
	return (
		<svg width={size ?? "13"} height={size ?? "14"} viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.9962 5.1204H10.0651C10.0575 5.11985 10.0499 5.11985 10.0423 5.11985H10.0686V3.31135C10.0686 2.44793 9.70551 1.61926 9.05927 1.00907C8.41302 0.398333 7.12813 0 6.21452 0C5.30091 0 4.0744 0.398333 3.42816 1.00907C2.78191 1.61981 2.41939 2.44793 2.41939 3.31135V5.11985H2.21507L2.21157 5.1204H1.49118C0.904489 5.1204 0.428711 5.57004 0.428711 6.12451V12.5C0.428711 13.0545 0.904489 13.5041 1.49118 13.5041H10.9962C11.5829 13.5041 12.0587 13.0545 12.0587 12.5V6.12451C12.0587 5.57004 11.5829 5.1204 10.9962 5.1204ZM4.33301 5.1204V3.31025C4.33301 2.7944 4.62315 2.31717 5.096 2.05953C5.32835 1.93263 5.5928 1.77264 6.21452 1.77264C6.83624 1.77264 7.15323 1.92932 7.39609 2.06228C7.86603 2.31883 8.15441 2.79385 8.15441 3.30694V5.1204H4.33301Z"
				fill={color ?? "#4D9CBF"}
			/>
		</svg>
	);
};

export default Lock;
