/* eslint-disable import/no-anonymous-default-export */
// Definitions of the routes of the app, please use those urls instead of manually write the urls
export default {
    home: "/",
    settings: "/customize",
    links: "/links",
    report: "/report",
    admin: "/admin",
    worksite: "/worksite",
    worksite_list: "/list-worksite"
}