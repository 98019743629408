import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import UnauthHome from "../components/UnauthHome";
import { CenteredDiv } from "../style";

const router = createBrowserRouter([
	{
		path: '*',
		element: <UnauthHome />,
	},
]);

const UnauthRouter: React.FC = () => {
	return (
		<CenteredDiv>
			<RouterProvider router={router} />
		</CenteredDiv>
	);
}


export default UnauthRouter;