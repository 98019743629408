import React from 'react';

export type Props = {
	color?: string;
};

const Rain: React.FC<Props> = ({ color }) => {
	return (
		<svg id="RainSVG" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M7.01544 10.8974C6.87411 10.6551 6.56391 10.5725 6.32163 10.712C6.20966 10.7762 6.12156 10.8845 6.08485 11.0203L5.07166 14.7978C5.00007 15.0676 5.1616 15.3484 5.43141 15.42C5.56724 15.4567 5.70307 15.4329 5.81503 15.3686C5.92883 15.3025 6.0151 15.1979 6.05181 15.0603L7.065 11.2828C7.10171 11.147 7.07785 11.0093 7.01361 10.8974H7.01544Z" fill={color ?? "#25465F"} />
			<path fillRule="evenodd" clipRule="evenodd" d="M3.64912 10.8974C3.50962 10.6551 3.19942 10.5725 2.95713 10.712C2.84333 10.7762 2.75523 10.8845 2.72036 11.0203L1.70716 14.7978C1.63558 15.0676 1.7971 15.3484 2.06508 15.42C2.20091 15.4567 2.33857 15.4329 2.45054 15.3686C2.56434 15.3025 2.65061 15.1979 2.68732 15.0603L3.70051 11.2828C3.73722 11.147 3.71336 11.0093 3.64912 10.8974Z" fill={color ?? "#25465F"} />
			<path fillRule="evenodd" clipRule="evenodd" d="M10.2368 10.8974C10.0954 10.6532 9.78707 10.5706 9.54295 10.712C9.43099 10.7762 9.34288 10.8845 9.30617 11.0203L8.29298 14.7978C8.2214 15.0713 8.38476 15.3503 8.6509 15.42C8.78673 15.4567 8.92439 15.4329 9.03636 15.3686C9.14649 15.3044 9.2401 15.1961 9.27314 15.0603L10.2863 11.2828C10.323 11.147 10.301 11.0112 10.2349 10.8974H10.2368Z" fill={color ?? "#25465F"} />
			<path fillRule="evenodd" clipRule="evenodd" d="M13.8142 10.8974C13.6728 10.6532 13.3645 10.5706 13.1203 10.712C13.0084 10.7762 12.9203 10.8845 12.8836 11.0203L11.8704 14.7978C11.7988 15.0713 11.9621 15.3503 12.2283 15.42C12.3641 15.4567 12.5018 15.4329 12.6137 15.3686C12.7239 15.3044 12.8175 15.1961 12.8505 15.0603L13.8637 11.2828C13.9004 11.147 13.8784 11.0112 13.8123 10.8974H13.8142Z" fill={color ?? "#25465F"} />
			<path d="M16.2773 6.58208C16.2773 7.80636 15.2825 8.8012 14.0582 8.8012H3.03976C1.51447 8.8012 0.277344 7.56407 0.277344 6.03327C0.277344 4.50247 1.51447 3.27085 3.03976 3.27085C3.03976 1.46105 4.50632 0 6.31061 0C7.59913 0 8.71327 0.745211 9.24373 1.83182C9.6402 1.6134 10.1027 1.48492 10.5873 1.48492C12.1181 1.48492 13.3552 2.72204 13.3552 4.25284C13.3552 4.32626 13.3516 4.40335 13.3461 4.47677C13.57 4.39968 13.8068 4.35746 14.0582 4.35746C15.2825 4.35746 16.2773 5.3523 16.2773 6.58208Z" fill={color ?? "#25465F"} />
		</svg>
	);
};

export default Rain;
