import Cross from "assets/svg/Cross";
import { CrossContainer, ModalHeaderContainer, Title, TitleContainer } from "./style";

type ModalHeaderProps = {
	title: string;
	pop: () => void;
    isTitleCentered?: boolean;
    showPop?: boolean;
};

const ModalHeader: React.FC<ModalHeaderProps> = ({ title, pop, isTitleCentered, showPop = true }) => {
    return (
        <ModalHeaderContainer>
            <TitleContainer justifyContent={isTitleCentered ? "center" :"space-between"}>
                <Title>{title}</Title>
            </TitleContainer>
            {showPop && (
                <CrossContainer onClick={pop}>
                    <Cross />
                </CrossContainer>
            )}
        </ModalHeaderContainer>
    )
}

export default ModalHeader;