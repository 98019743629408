import React, { createContext, useContext, useEffect, useState } from 'react';

import Loader from 'components/Loader';
import { CounterByTask } from 'api/types/counter';
import { counterByTask } from 'api/routes/counter';
import { ReferentialResponse } from 'api/types/ref';
import i18n from 'utils/lang';
import { showError } from 'utils/error';
import { usePreferences } from 'providers/preferences';
import { EOTPCategory } from 'utils/constants';
import { useClient } from 'providers/client';
import { SourceTypeFromTask } from 'api/types/source';
import { useReports } from 'providers/ReportsProvider';
import { ReportStatus } from 'api/types/report';
import { getReportCommentByTask } from 'api/routes/comment';

import { ReportContext } from '../..';

export type ReportDataTasksContextType = {
	tasks: CounterByTask[];
	sources: SourceTypeFromTask[];
	fetchCounters: () => void;
};

export const ReportDataTasksContext = createContext<ReportDataTasksContextType>({
	tasks: [],
	fetchCounters: () => { },
	sources: [],
});

type Props = {
	date: string | undefined;
	categoryID: number;
	labelsEOTP: Readonly<ReferentialResponse[]>;
	children: React.ReactNode;
};

export const ReportDataTasksProvider: React.FC<Props> = ({ date, categoryID, labelsEOTP, children }) => {
	const { clientHandler: { client } } = useClient();
	const { currentReport } = useReports();
	const { refresh } = useContext(ReportContext);
	const { preferences } = usePreferences();

	const [tasks, setTasks] = useState<CounterByTask[] | undefined>(undefined);
	const [sources, setSources] = useState<SourceTypeFromTask[]>([]);

	const responseByType = (isEotp: boolean, data: CounterByTask[], favEmpty: CounterByTask[]): CounterByTask[] => {
		if (isEotp) {
			return data.map((e) => ({
				...e,
				sources: e.sources
					.map((s) => ({
						...s,
						counters: s.counters?.filter((c) => c.quantity !== 0),
					}))
					.filter((s) => !s.counters || (s.counters && s.counters.length > 0)),
			}))
		}
		const missingFavEmpty = favEmpty.filter((e) => !data.some((d) => d.task === e.task));
		return [...missingFavEmpty, ...data]
			.map((d) => ({
				...d,
				sources: d.sources.filter((s) => s.CategoryId === categoryID),
			}))
			.sort((a, b) => (a.task?.length ?? 0) - (b.task?.length ?? 0))
	}

	const fetchCounters = (): void => {
		if (!date || !currentReport?._id) return;

		getReportCommentByTask(client, currentReport?._id).then((allCommentsBytasks) => {
			const uniqueTaskIDPerComment = Array.from(new Set(allCommentsBytasks.map(e => e.task).filter(e => e !== undefined))) as string[];
			const prefsEOTPtaskIDs = preferences.tasks?.find((e) => e.categoryID === EOTPCategory._id)?.refs.map((r) => r.id).filter(e => e !== undefined) as string[];
			const extraEOTPTasksIDs: string[] = Array.from(new Set(uniqueTaskIDPerComment.concat(prefsEOTPtaskIDs)));

			// HACK: Merge codes from every category, could be risky if there are duplicates codes in different categories
			const prefsCodeIDs = preferences.tasks?.filter((t) => t.categoryID !== EOTPCategory._id)?.map((t) => t.refs.map((r) => r.id));
			counterByTask(
				client,
				new Date(date),
				(currentReport?.status ?? ReportStatus.Invalidated) === ReportStatus.Invalidated ? extraEOTPTasksIDs : undefined,
				(currentReport?.status ?? ReportStatus.Invalidated) === ReportStatus.Invalidated ? prefsCodeIDs?.flat() : undefined,
				currentReport?.status !== ReportStatus.Invalidated,
				currentReport?._id,
			)
				.then((data) => {
					const favEmpty: CounterByTask[] = extraEOTPTasksIDs?.map((e) => ({ task: e, sources: [], rawTask: e })) ?? [];
					if (!data) return;

					const response = responseByType(categoryID === EOTPCategory._id, data, favEmpty);
					const computeTitle = (task: string): string => {
						const label = labelsEOTP.find((l) => l.id === task)?.label ?? task;
						return label + " (" + task + ")";
					};

					const respTasks =
						response.map(
							(e) => (
								{
									...e,
									sources: e.sources
										.map((source) => (
											{
												...source,
												counters: source.counters && source.counters.filter((counter) => counter.include)
											}
										))
										.filter((source) => source.counters && source.counters.length > 0),
									task: !!e.task ? computeTitle(e.task) : i18n.t('undefined'),
									rawTask: e.task,
								}
							))
							.filter((e) => e.sources.length !== 0 || favEmpty.findIndex((t) => t.task === e.rawTask) !== -1);

					setTasks(
						categoryID === EOTPCategory._id ? respTasks.filter((d) => d.sources.length > 0) : respTasks,
					);

					setSources(response.flatMap((d) => d.sources));
				})
				.catch(showError('Failed to fetch counter by task'));
		}).catch(showError('Failed to fetch counter by task'));
	};

	useEffect(() => {
		if (date) {
			setTasks(undefined);
			fetchCounters();
		}
	}, [date, categoryID, refresh]);

	if (!tasks) {
		return <Loader />;
	}

	return (
		<ReportDataTasksContext.Provider
			value={{
				tasks,
				fetchCounters,
				sources,
			}}
		>
			{children}
		</ReportDataTasksContext.Provider>
	);
};

export const useReportDataTasks = (): ReportDataTasksContextType => useContext(ReportDataTasksContext);
