import React, { useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import { createCounter } from 'api/routes/counter';
import { CategoryType } from 'api/types/category';
import Button, { Type as ButtonType } from 'components/Button';
import { useModal } from 'components/Modal';
import Search, { SearchType } from 'components/Search';
import i18n from 'utils/lang';
import { CounterType } from 'api/types/counter';
import ModalHeader from 'components/Modal/ModalHeader';
import { ModalContainer } from 'components/Modal/style';
import { useSearch } from 'components/Search/useSearch';
import { useClient } from 'providers/client';
import { showError } from 'utils/error';
import { PRODUCTION_TYPE_REFERENCE } from 'utils/constants';
import { createComment } from 'api/routes/comment';
import { useReports } from 'providers/ReportsProvider';
import { generateGenericPreparedCounter } from 'api/routes/preparedcounter';

import { Favs } from '.';
import { EOTPAccordion } from './EOTPAccordion';
import { ButtonContainer } from './style';
import { SelectableReferential, SelectableSource } from './type';
import useData from './useData';

type Props = {
	date: string;
	category: CategoryType;
	currentCategory: CategoryType;
	productionCategory?: CategoryType;
	refresh: boolean;
	setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
	createCounterFn: typeof createCounter;
};

const ModalEOTP: React.FC<Props> = ({ date, category, currentCategory, productionCategory, refresh, setRefresh, createCounterFn }) => {
	const { clientHandler: { client } } = useClient();
	const { pop } = useModal();
	const modalRef = useRef() as React.MutableRefObject<HTMLDivElement>;
	useOnClickOutside(modalRef, pop);

	const { fav, setFav, refs, setRefs, sources } = useData(true, category, { date, currentCategory });
	const { currentReport } = useReports();

	const create = (): void => {
		const isCurrentCategoryProduction = currentCategory.typeReference === PRODUCTION_TYPE_REFERENCE;
		const selectedRefs = refs.filter((e) => e.isSelected === true);
		const selectedSourcesWithCounters = sources.filter(
			(src): src is SelectableSource & { counters: CounterType[] } => !!src.counters,
		);

		if (productionCategory) {
			if (currentReport && currentReport._id && isCurrentCategoryProduction) {
				Promise.all(
					selectedRefs.map(
						(e) => createComment(client, currentReport._id ?? -1, { content: "", task: e.id })
					)
				).catch(console.error);
			}

			generateGenericPreparedCounter(
				client,
				{
					refs: selectedRefs,
					CategoryId: productionCategory._id,
					date
				}
			).catch(showError('Failed to create prepared counters'));
		}
		if (!isCurrentCategoryProduction) {
			Promise.all(
				selectedRefs.map((ref) =>
					selectedSourcesWithCounters.map((src) =>
						src.counters.map((counter) =>
							createCounterFn(client, {
								task: ref.id,
								CategoryId: currentCategory._id.toString(),
								SourceId: src._id.toString(),
								quantity: 0,
								...counter,
							}),
						),
					),
				),
			).catch(showError('Failed to create counters'));
		}

		setTimeout(() => {
			setRefresh(!refresh);
		}, 500);
		pop();
	};

	const {
		searchValue,
		handleSearchChange,
		setSearchValue,
		filteredItems: filteredRefs,
	} = useSearch<SelectableReferential>(refs, ['label', 'title'], true);
	return (
		<ModalContainer ref={modalRef}>
			<ModalHeader title={i18n.t('add_a_title', { title: 'EOTP' })} pop={pop} />
			<Search type={SearchType.FullScreen} value={searchValue} onChange={handleSearchChange} clearText={() => setSearchValue('')} />
			<Favs fav={fav} setFav={setFav} />
			<EOTPAccordion refs={refs} filteredRefs={filteredRefs} setRefs={setRefs} />
			<ButtonContainer>
				<Button disabled={!refs || refs.filter((e) => e.isSelected === true).length === 0}
					type={ButtonType.modalValidator} onClick={create}>
					{i18n.t('validate')}
				</Button>
			</ButtonContainer>
		</ModalContainer>
	);
};

export default ModalEOTP;
